import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {UrlStore} from '@shared/helpers/url-store';

export const TEMPORARY_REDIRECT = 307;  // Redirects if REST API sends back redirect


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.auth.getAccessToken()}`
            }
        });
        return next.handle(request).pipe(tap((succ: any) => {
                if (succ instanceof HttpErrorResponse) {
                    if (succ.status !== TEMPORARY_REDIRECT) {
                        return;
                    }
                    this.router.navigate([UrlStore.ui.siteDown]);
                }
            },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (
                        err.status !== TEMPORARY_REDIRECT
                    ) {
                        return;
                    }
                    this.router.navigate([UrlStore.ui.siteDown]);
                }
            }));
    }
}

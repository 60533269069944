import {Observable, timer} from 'rxjs';
import {first, switchMapTo} from 'rxjs/operators';

const DEFAULT_POLLING_INTERVAL = 1000;


export function polling<T>(
    stopWhen: (response: any) => boolean,
    pollingInterval: number = DEFAULT_POLLING_INTERVAL
): (source$: Observable<T>) => Observable<T> {
    return (source$: Observable<T>) =>
        timer(0, pollingInterval)
            .pipe(
                switchMapTo(source$),
                first(stopWhen)
            );
}

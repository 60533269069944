import {GridApi, ColumnApi, GridReadyEvent, Module} from 'ag-grid-community';
// import {AllModules} from 'ag-grid-community';
import {Output, EventEmitter, Directive} from '@angular/core';
import {GridApis} from '@models/lookup';

@Directive()
export abstract class AgGridApiDirective {
    public gridApi: GridApi;
    public gridColumnApi: ColumnApi;
    // public modules: Module[] = AllModules;

    @Output() gridApiReady: EventEmitter<GridApis> = new EventEmitter();

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApiReady.emit({gridApi: this.gridApi, columnApi: this.gridColumnApi});

    }

    onGridReadyAutoSize(params: GridReadyEvent) {
        this.onGridReady(params);
        if (this.gridColumnApi) {
            this.gridColumnApi.autoSizeAllColumns();
        }
    }

    onGridReadyAutoSizeSelectedColumn(params: GridReadyEvent) {
        this.onGridReady(params);
        const keys = ['proposalId', 'advertiserName', 'agencyName', 'clientDeadlineDate',
        'submittedDate', 'flightRange', 'buyType', 'status', 'expirationDate', 'primaryReviewerName', 'primaryAeName',
        'createdBy', 'createdDate', 'grossRevenue', 'netRevenue', 'spotRate', 'spotCount',
        'paidSpots', 'bonusSpots', 'daysInPipeline', 'closeProbability'];
        if (this.gridColumnApi) {
            this.gridColumnApi.autoSizeColumns(keys);
        }
    }

    onGridReadyRemoveOverlays() {
        this.gridApi.showNoRowsOverlay();
    }

    onGridReadySizeToFit(params: GridReadyEvent) {
        this.onGridReadyAutoSize(params);
        this.gridApi.sizeColumnsToFit();
    }

    onGridReadySizeToFitAndResetRowHeight(params: GridReadyEvent) {
        this.onGridReadySizeToFit(params);
        this.gridApi.resetRowHeights();
    }

    refreshCells() {
        this.gridApi.refreshCells();
    }

    resizeCellsToFit() {
        this.gridApi?.sizeColumnsToFit();
        this.gridColumnApi?.autoSizeAllColumns();
    }

    setColumnGroupOpened(colName: string, isOpened: boolean) {
        this.gridColumnApi.setColumnGroupOpened(colName, isOpened);
    }

    setColumnVisible(colName: string, isVisible: boolean) {
        this.gridColumnApi.setColumnVisible(colName, isVisible);
    }

    setColumnPinned(colName: string, side: string = null) {
        this.gridColumnApi.setColumnPinned(colName, side);
    }
}


import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    AdvertiserItem,
    BooleanLookupItem,
    LookupItem,
    StationConstraintMix,
    ConstraintMix,
    FilterParams,
    PairedLookupItem,
    ChannelConstraintMix,
    NumericLookupItem,
    ClientLogo,
    ChannelTree,
    LocalSpotTypeLookupItem,
    ChannelLookupParams,
    DaypartLookupParams,
    SpotTypeLookupParams,
    SpotLengthLookupParams, DaypartHours, DOWLookupItem, LookupItemName, SalesForceId,
} from '@models/lookup';
import {UrlStore} from '@shared/helpers/url-store';
import {Observable, of} from 'rxjs';
import {DEFAULT_AGENCY_LOOKUP, generateCachedGet, generateUncachedGet,
    SCREEN_NAME_INVENTORY_REPORT, SCREEN_NAME_LAUNCH_PAD_BUILD,
    SCREEN_NAME_RATE_GUIDE,
    SCREEN_NAME_UNMAPPED_ADVERTISERS_AGENCIES} from '@shared/helpers/helpers';
import {switchMap} from 'rxjs/operators';
import {cloneDeep} from 'lodash';
import {ProposalData} from '@models/proposal-optimize/proposal-optimize';

const SKIP_TO_SPOT_LENGTH_DAYPART = '-1';
const DISPLACED_OPTIONS = [
    {id: 1, name: 'Exclude Displaced Spots'},
    {id: 2, name: 'Include Displaced Spots'},
];
export const BUDGET_OPTIONS = [
    {id: 1, name: 'Gross', flag: false},
    {id: 2, name: 'Net', flag: true},
];
const NO_CHARGE_OPTIONS = [
    {id: 1, name: 'Exclude No Charge Spots', flag: true},
    {id: 2, name: 'Include No Charge Spots', flag: false},
];

const SCENARIO_OPTIONS = [
    {id: 1, name: 'Include Non-Primary Scenarios', flag: true},
    {id: 2, name: 'Exclude Non-Primary Scenarios', flag: false},
];

@Injectable({
    providedIn: 'root'
})
export class LookupService {

    cacheData: any = {};

    constructor(private http: HttpClient) {
    }

    getMarkets(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.markets,
            {screenName});
    }

    getStations(screenName?: string, clearCache: boolean = false): Observable<StationConstraintMix[]> {
        return this.generateCachedGet<StationConstraintMix>(clearCache,
            UrlStore.api.lookups.stations,
            {screenName});
    }

    getChannelTree(scenarioId: number, screenName?: string, clearCache: boolean = true): Observable<any> {
        return this.generateCachedGet<any>(clearCache,
            UrlStore.api.lookups.channelTree,
            {scenarioId, screenName});
    }

    getLocalChannels(
        scenarioId?: number,
        proposalLineId?: number
    ): Observable<ChannelConstraintMix[]> {
        if (localStorage.getItem('channels')) {
            return of(JSON.parse(localStorage.getItem('channels')));
        } else {
            return this.getChannels({scenarioId, proposalLineId});
        }
    }

    getChannels(
        lookupParams: ChannelLookupParams
    ): Observable<ChannelConstraintMix[]> {
        let params = {};
        if (lookupParams.scenarioId) {
            params = {...params, scenarioId: lookupParams.scenarioId};
        }
        if (lookupParams.proposalLineId) {
            params = {...params, proposalLineId: lookupParams.proposalLineId};
        }
        if (lookupParams.categoryIds) {
            params = {...params, categoryIds: lookupParams.categoryIds};
        }
        if (lookupParams.includeInactive) {
            params = {...params, includeInactive: lookupParams.includeInactive};
        }
        if (lookupParams.clockDates) {
            params = {...params, clockDates: lookupParams.clockDates};
        }
        params = {...params, screenName: lookupParams.screenName};
        return this.generateCachedGet<ChannelConstraintMix>(
            lookupParams.clearCache,
            UrlStore.api.lookups.channels,
            Object.keys(params).length === 0 ? undefined : params
        );
    }

    getPeriods(clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache, UrlStore.api.lookups.periods);
    }

    getRatingsDaypart(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.ratingsDayparts,
            {screenName, source: 'nielsen'});
    }

    getRatingsDaypartEdison(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.ratingsDayparts,
            {screenName, source: 'edison'});
    }

    getPriorityCodes(
        channelIds?: number[],
        spotTypeIds?: number[],
        daypartIds?: number[],
        clearCache: boolean = false
    ): Observable<LookupItem[]> {
        const params: any = {};

        if (channelIds) {
            params.channelIds = channelIds;
        }
        if (spotTypeIds) {
            params.spotTypeIds = spotTypeIds;
        }
        if (daypartIds) {
            params.daypartIds = daypartIds;
        }
        params.screenName = SCREEN_NAME_INVENTORY_REPORT;

        return this.generateCachedGet<LookupItem>(
            clearCache,
            UrlStore.api.lookups.priorityCodes,
            params
        );
    }

    getDisplacedOptions(): Observable<LookupItem[]> {
        return of(DISPLACED_OPTIONS);
    }

    getBudgetOptions(): Observable<BooleanLookupItem[]> {
        return of(BUDGET_OPTIONS);
    }

    getNoChargeOptions(): Observable<LookupItem[]> {
        return of(NO_CHARGE_OPTIONS);
    }

    getScenarioOptions(): Observable<LookupItem[]> {
        return of(SCENARIO_OPTIONS);
    }

    getLocalDayparts(
        lookupParams: DaypartLookupParams
    ): Observable<LookupItem[]> {
        if (
            localStorage.getItem('channelTree')
            && lookupParams.channelIds
            && lookupParams.channelIds.length > 0
            && lookupParams.channelIds[0] !== undefined
            && lookupParams.spotTypeIds
            && lookupParams.spotTypeIds.length > 0
            && lookupParams.spotTypeIds[0] !== undefined
        ) {
            const channelTree: ChannelTree = JSON.parse(localStorage.getItem('channelTree'));
            const selectedChannels = lookupParams.channelIds;
            const selectedSpotTypes = lookupParams.spotTypeIds;
            const tmp = [];
            const tmpIds = new Set<number>();
            selectedChannels.forEach(selectedChannel => {
                selectedSpotTypes.forEach(selectedSpotType => {
                    if (channelTree[selectedChannel].hasOwnProperty(selectedSpotType)) {
                        // unravel the tree to get dayparts
                        for (const daypart in channelTree[selectedChannel][selectedSpotType].dayparts) {
                            if (daypart !== SKIP_TO_SPOT_LENGTH_DAYPART &&
                                channelTree[selectedChannel][selectedSpotType].dayparts.hasOwnProperty(daypart) &&
                                !tmpIds.has(Number(daypart))) {
                                tmp.push({
                                    id: channelTree[selectedChannel][selectedSpotType].dayparts[daypart].id,
                                    name: channelTree[selectedChannel][selectedSpotType].dayparts[daypart].name,
                                    displayOrder: channelTree[selectedChannel][selectedSpotType].dayparts[daypart].displayOrder
                                });
                                tmpIds.add(Number(daypart));
                            }
                        }
                    }
                });
            });
            return of(tmp.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1));
        }
        return this.getDayparts(lookupParams);
    }

    getLocalDaypartNames(
        lookupParams: DaypartLookupParams
    ): Observable<LookupItemName[]> {
        return this.getLocalDayparts(lookupParams).pipe(
            switchMap((localDayparts) => {
                const localDaypartNames: LookupItemName [] = Object.values(localDayparts.reduce(
                    (daypartNamesAcc: object, daypart: LookupItem) => {
                        if (!(daypartNamesAcc[daypart.name] &&
                            daypartNamesAcc[daypart.name].displayOrder > daypart.displayOrder)) {

                            if (daypartNamesAcc.hasOwnProperty(daypart.name)) {
                                daypartNamesAcc[daypart.name] = {
                                    ...daypartNamesAcc[daypart.name],
                                    ...cloneDeep(daypart)
                                };
                            } else {
                                daypartNamesAcc[daypart.name] = cloneDeep(daypart);
                            }

                            if (daypartNamesAcc[daypart.name].hasOwnProperty('originalIds')) {
                                daypartNamesAcc[daypart.name].originalIds = new Set([
                                    ...daypartNamesAcc[daypart.name].originalIds,
                                    ...new Set([daypartNamesAcc[daypart.name].id])
                                ]);
                            } else {
                                daypartNamesAcc[daypart.name].originalIds = new Set([daypartNamesAcc[daypart.name].id]);
                            }
                        }
                        daypartNamesAcc[daypart.name].originalIds.add(daypartNamesAcc[daypart.name].id);
                        return daypartNamesAcc;
                    }, {}));
                return of(localDaypartNames.sort(
                    (daypartA, daypartB) =>
                        `${daypartA.displayOrder}-${daypartA.name}` >
                        `${daypartB.displayOrder}-${daypartB.name}` ? 1 : -1));
            }));
    }

    getDayparts(
        lookupParams: DaypartLookupParams
    ): Observable<LookupItem[]> {
        const isParams = lookupParams.channelIds
            || lookupParams.spotTypeIds
            || lookupParams.scenarioId
            || lookupParams.proposalLineId
            || lookupParams.displayOrderedOnly !== undefined
            || lookupParams.lowestLevelOnly !== undefined
            || lookupParams.notUsedInOrders !== undefined
            || lookupParams.includeGlobalCustom !== undefined
            || lookupParams.screenName;

        const params: any = isParams ? {} : undefined;

        if (lookupParams.channelIds) {
            params.channelIds = lookupParams.channelIds;
        }
        if (lookupParams.spotTypeIds) {
            params.spotTypeIds = lookupParams.spotTypeIds;
        }
        if (lookupParams.scenarioId) {
            params.scenarioId = lookupParams.scenarioId;
        }
        if (lookupParams.proposalLineId) {
            params.proposalLineId = lookupParams.proposalLineId;
        }
        if (lookupParams.displayOrderedOnly !== undefined) {
            params.displayOrderedOnly = lookupParams.displayOrderedOnly;
        }
        if (lookupParams.lowestLevelOnly !== undefined) {
            params.lowestLevelOnly = lookupParams.lowestLevelOnly;
        }
        if (lookupParams.notUsedInOrders !== undefined) {
            params.notUsedInOrders = lookupParams.notUsedInOrders;
        }
        if (lookupParams.includeGlobalCustom !== undefined) {
            params.includeGlobalCustom = lookupParams.includeGlobalCustom;
        }
        params.screenName = lookupParams.screenName;
        return this.generateCachedGet<LookupItem>(
            lookupParams.clearCache,
            UrlStore.api.lookups.dayparts,
            params
        );
    }

    getCustomDaypartDOW(clearCache: boolean = false): DOWLookupItem[] {
        return [
            {
                name: 'M-F',
                mask: {
                    monday: true, tuesday: true, wednesday: true, thursday: true, friday: true,
                    saturday: false, sunday: false
                }
            },
            {
                name: 'Sa-Su',
                mask: {
                    monday: false, tuesday: false, wednesday: false, thursday: false, friday: false,
                    saturday: true, sunday: true
                }
            },
            {
                name: 'M-Su',
                mask: {
                    monday: true, tuesday: true, wednesday: true, thursday: true, friday: true,
                    saturday: true, sunday: true
                }
            }
        ];
    }

    getRateGuideDayparts(
        channelIds?: number[],
        spotTypeIds?: number[],
        clearCache: boolean = false
    ): Observable<LookupItem[]> {
        const params: any = {};

        if (channelIds) {
            params.channelIds = channelIds;
        }
        if (spotTypeIds) {
            params.spotTypeIds = spotTypeIds;
        }
        params.screenName = SCREEN_NAME_RATE_GUIDE;

        return this.generateCachedGet<LookupItem>(
            clearCache,
            UrlStore.api.rateGuide.dayparts,
            params
        );
    }

    getCustomDaypartHours(
        channelId: number, spotTypeId: number, clearCache: boolean = false): NumericLookupItem[] {
        const channelTree  = JSON.parse(localStorage.getItem('channelTree'));
        return channelTree[channelId][spotTypeId].customHours;
    }

    getBulkCustomDaypartHours(
        channelIds: number [], spotTypeIds: number[], clearCache: boolean = false): NumericLookupItem[] {
        const channelTree  = JSON.parse(localStorage.getItem('channelTree'));
        const unionedHours = {};
        channelIds.forEach(channelId => {
           spotTypeIds.forEach(spotTypeId => {
               channelTree[channelId][spotTypeId]?.customHours?.forEach(hour => {
                  if (!(hour.id in unionedHours)) {
                      unionedHours[hour.id] = hour;
                  }
               });
           });
        });
        return Object.values(unionedHours);
    }

    getCustomDaypartHoursGlobal(): Observable<NumericLookupItem[]> {
        return this.generateCachedGet<NumericLookupItem>(false, UrlStore.api.lookups.globalCustomDaypartHours);
    }

    getDaypartHours(daypartId: number): Observable<DaypartHours> {
        const url = `${UrlStore.api.lookups.daypartHours}?daypartId=${daypartId}`;
        return this.http.get<DaypartHours>(url);
    }

    getLocalSpotTypes(
        lookupParams: SpotTypeLookupParams
    ): Observable<LocalSpotTypeLookupItem[]> {
        if (localStorage.getItem('channelTree') && lookupParams.channelIds && lookupParams.channelIds.length > 0
            && lookupParams.channelIds[0] !== undefined) {
            const channelTree = JSON.parse(localStorage.getItem('channelTree'));
            const tmp = [];
            const tmpIds = new Set<number>();
            // unravel the tree to get spot types
            const selectedChannels: number [] = lookupParams.channelIds;
            selectedChannels.forEach(selectedChannel => {
                for (const spotType in channelTree[selectedChannel]) {
                    if (channelTree[selectedChannel].hasOwnProperty(spotType) && !tmpIds.has(Number(spotType))) {
                        tmp.push(
                            {
                                id: channelTree[selectedChannel][spotType].id,
                                name: channelTree[selectedChannel][spotType].name,
                                divisor: channelTree[selectedChannel][spotType].divisor,
                                class: channelTree[selectedChannel][spotType].class,
                                displayOrder: channelTree[selectedChannel][spotType].displayOrder,
                            }
                        );
                        tmpIds.add(Number(spotType));
                    }
                }
            });
            return of(tmp.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1));
        } else {
            return this.getSpotTypes(lookupParams);
        }
    }

    getSpotTypes(
        lookupParams: SpotTypeLookupParams
    ): Observable<LookupItem[]> {
        const params: any = lookupParams.channelIds
        || lookupParams.scenarioId
        || lookupParams.proposalLineId
        || lookupParams.onlyBaseSpots
        || lookupParams.screenName
            ? {}
            : undefined;
        if (lookupParams.channelIds) {
            params.channelIds = lookupParams.channelIds;
        }
        if (lookupParams.scenarioId) {
            params.scenarioId = lookupParams.scenarioId;
        }
        if (lookupParams.proposalLineId) {
            params.proposalLineId = lookupParams.proposalLineId;
        }

        if (lookupParams.onlyBaseSpots) {
            params.only_base_spot_types = true;
        }

        params.screenName = lookupParams.screenName;

        return this.generateCachedGet<LookupItem>(
            lookupParams.clearCache,
            UrlStore.api.lookups.spotTypes,
            params
        );
    }

    getRateGuideSpotTypes(
        channelIds?: number[],
        clearCache: boolean = false
    ): Observable<LookupItem[]> {
        const params: any = {};
        if (channelIds) {
            params.channelIds = channelIds;
        }

        params.screenName = SCREEN_NAME_RATE_GUIDE;
        return this.generateCachedGet<LookupItem>(
            clearCache,
            UrlStore.api.rateGuide.spotTypes,
            params
        );
    }

    getAdvertisers(filterParams: FilterParams, clearCache: boolean = false, forceActiveFlag = true): Observable<AdvertiserItem[]> {
        filterParams = {forceActive: forceActiveFlag, ...filterParams};
        return this.generateCachedGet<AdvertiserItem>(clearCache, UrlStore.api.lookups.advertisers, filterParams);
    }

    checkSalesForceId(salesforceId: string): Observable<SalesForceId> {
        const url = `${UrlStore.api.lookups.checkSalesForceid}?salesForceId=${salesforceId}`;
        return this.http.get<SalesForceId>(url);
    }

    createAdvertiser(name: string): Observable<{ item: LookupItem, isNew: boolean }> {
        return this.http.post<{ item: LookupItem, isNew: boolean }>(UrlStore.api.lookups.advertisers, {name,
        screenName: SCREEN_NAME_LAUNCH_PAD_BUILD});
    }

    getAgencies(filterParams: FilterParams, clearCache: boolean = false, forceActiveFlag = true): Observable<LookupItem[]> {
        filterParams = {forceActive: forceActiveFlag, ...filterParams};
        return this.generateCachedGet<LookupItem>(
            clearCache, UrlStore.api.lookups.agencies, filterParams);
    }

    createAgency(name: string): Observable<{ item: LookupItem, isNew: boolean }> {
        return this.http.post<{ item: LookupItem, isNew: boolean }>(UrlStore.api.lookups.agencies, {name,
            screenName: SCREEN_NAME_LAUNCH_PAD_BUILD});
    }

    getAccountExecutives(filterParams: FilterParams, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache, UrlStore.api.lookups.accountExecutives, filterParams);
    }

    getAppUsers(filterParams: FilterParams, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache, UrlStore.api.lookups.appUsers, filterParams);
    }

    getSalesCoordinators(filterParams: FilterParams, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache, UrlStore.api.lookups.salesCoordinators, filterParams);
    }

    getPlanningMembers(filterParams: FilterParams, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache, UrlStore.api.lookups.planningMembers, filterParams);
    }

    getProposalStatuses(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.scenarioStatus,
            {screenName});
    }

    getCloseProbabilities(screenName: string= '', clearCache: boolean = false): Observable<NumericLookupItem[]> {
        return this.generateCachedGet<NumericLookupItem>(clearCache,
            UrlStore.api.lookups.closeProbabilities,
            {screenName});
    }

    getSegmentation(clearCache: boolean = false): LookupItem[] {
        return [
            {
                id: 0,
                name: 'channelDaypart',
                displayName: 'Channel/Daypart/Program'
            },
            {
                id: 1,
                name: 'channel',
                displayName: 'Channel'
            }
        ];
    }

    getSXMDaypart(clearCache: boolean = false): LookupItem[] {
        return [
            {
                id: 0,
                name: 'M-F 6a-10a'
            },
            {
                id: 1,
                name: 'M-F 6p-7p'
            },
            {
                id: 2,
                name: 'M-Su 6a-6p'
            }
        ];
    }

    getType(clearCache: boolean = false): LookupItem[] {
        return [
            {
                id: 0,
                name: 'Advertiser'
            },
            {
                id: 1,
                name: 'Agency'
            }
        ];
    }

    getStatus(clearCache: boolean = false): LookupItem[] {
        return [
            {
                id: 0,
                name: 'Mapped'
            },
            {
                id: 1,
                name: 'Unmapped'
            }
        ];
    }

    getWOStatus(clearCache: boolean = false): LookupItem[] {
        return [
            {
                id: 0,
                name: 'Active'
            },
            {
                id: 1,
                name: 'Not Active'
            }
        ];
    }

    getActive(clearCache: boolean = false): LookupItem[] {
        return [
            {
                id: 0,
                name: 'Yes'
            },
            {
                id: 1,
                name: 'No'
            }
        ];
    }

    getHasLiveReads(clearCache: boolean = false): LookupItem[] {
        return [
            {
                id: 0,
                name: 'Yes'
            },
            {
                id: 1,
                name: 'No'
            }
        ];
    }

    getIsRevision(clearCache: boolean = false): LookupItem[] {
        return [
            {
                id: 0,
                name: 'Yes'
            },
            {
                id: 1,
                name: 'No'
            }
        ];
    }

    getIsGlobalTemplate(clearCache: boolean = false): LookupItem[] {
        return [
            {
                id: 0,
                name: 'No'
            },
            {
                id: 1,
                name: 'Yes'
            }
        ];
    }

    getAutoApproveStatus(clearCache: boolean = false): LookupItem[] {
        return [
            {
                id: 0,
                name: 'Yes'
            },
            {
                id: 1,
                name: 'No'
            }
        ];
    }

    getProposalProName(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateUncachedGet<LookupItem>(UrlStore.api.lookups.proposalProNames,
            {sourceSystem: 'ui',
        screenName});
    }

    getWOOriginalNamesByType(filterParams?: FilterParams, clearCache: boolean = false, rowData?: any): Observable<LookupItem[]> {
        const screenName: string = SCREEN_NAME_UNMAPPED_ADVERTISERS_AGENCIES;
        if (rowData && rowData.type === 'Agency') {
            filterParams = {type: ['Agency'], screenName};
        }
        else{
             filterParams = {type: ['Advertiser'], screenName};
        }
        return this.generateCachedGet<LookupItem>(clearCache, UrlStore.api.admin.woAdvertisersAgenciesOriginal, filterParams);
    }

    getWONames(filterParams?: FilterParams, clearCache: boolean = false): Observable<string[]> {
        return this.generateCachedGet<LookupItem>(clearCache, UrlStore.api.admin.woAdvertisersAgencies, filterParams)
            .pipe(
                switchMap((items: LookupItem[]) => of([...new Set(items.map(item => item.name))]))
            );
    }

    getWOOriginalNames(filterParams?: FilterParams, clearCache: boolean = false): Observable<string[]> {
        return this.generateCachedGet<LookupItem>(clearCache, UrlStore.api.admin.woAdvertisersAgenciesOriginal, filterParams)
            .pipe(
                switchMap((items: LookupItem[]) => of([...new Set(items.map(item => item.name))]))
            );
    }

    getUnmappedNames(filterParams?: FilterParams, clearCache: boolean = false): Observable<string[]> {
        return this.generateCachedGet<LookupItem>(clearCache, UrlStore.api.admin.unmappedAdvertisersAgencies, filterParams)
            .pipe(
                switchMap((items: LookupItem[]) => of([...new Set(items.map(item => item.name))]))
            );
    }


    getCategories(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.categories,
            {screenName});
    }

    getNielsenNames(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.nielsenOrEdisonNames,
            {screenName, source: 'nielsen'});
    }

    getEdisonNames(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.nielsenOrEdisonNames,
            {screenName, source: 'edison'});
    }

    getUserRegions(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.userRegions,
            {screenName});
    }

    getUserRoles(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.userRoles,
            {screenName});
    }

    getPricingModels(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.pricingModels,
            {screenName});
    }

    getBuyTypes(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.buyTypes,
            {screenName});
    }

    getOptPriority(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.optPriorities,
            {screenName});
    }

    getExcludes(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.exclusions,
            {screenName});
    }

    getRatingsDemos(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.demographic,
            {screenName});
    }

    getRatingsDemosEdison(source: string, screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.demographicEdison,
            {source, screenName});
    }


    getSurveys(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache,
            UrlStore.api.lookups.survey,
            {screenName});
    }

    getMetrics(screenName?: string, clearCache: boolean = false): Observable<PairedLookupItem[]> {
        return this.generateCachedGet<PairedLookupItem>(clearCache,
            UrlStore.api.proposalPro.proposalMetrics,
            {screenName});
    }

    getOptSegmentation(screenName?: string, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<PairedLookupItem>(clearCache,
            UrlStore.api.lookups.optSegmentations,
            {screenName});
    }

    getLocalSpotLengths(
        lookupParams: SpotLengthLookupParams
    ): Observable<ConstraintMix[]> {
        if (
            localStorage.getItem('channelTree')
            && lookupParams.channelIds
            && lookupParams.channelIds.length > 0
            && lookupParams.channelIds[0] !== undefined
            && lookupParams.spotTypeIds
            && lookupParams.spotTypeIds.length > 0
            && lookupParams.spotTypeIds[0] !== undefined
            && lookupParams.daypartIds
            && lookupParams.daypartIds.length > 0
            && lookupParams.daypartIds[0] !== undefined
        ) {
            const channelTree = JSON.parse(localStorage.getItem('channelTree'));
            const selectedChannels = lookupParams.channelIds;
            const selectedSpotTypes = lookupParams.spotTypeIds;
            const selectedDayparts = lookupParams.daypartIds;
            const tmp = [];
            const tmpIds = new Set<number>();

            selectedChannels.forEach(selectedChannel => {
                selectedSpotTypes.forEach(selectedSpotType => {
                    selectedDayparts.forEach(selectedDaypart => {
                        if (channelTree[selectedChannel].hasOwnProperty(selectedSpotType)
                            && channelTree[selectedChannel][selectedSpotType].dayparts.hasOwnProperty(selectedDaypart)) {

                            // unravel the tree to get spot lengths
                            for (const spotLength in channelTree[selectedChannel][
                                selectedSpotType].dayparts[selectedDaypart].spotLengths) {
                                if (channelTree[selectedChannel][selectedSpotType].dayparts[
                                    selectedDaypart].spotLengths.hasOwnProperty(spotLength)) {
                                    const spotLengthObj = channelTree[selectedChannel][
                                        selectedSpotType].dayparts[selectedDaypart].spotLengths[spotLength];
                                    if (!tmpIds.has(spotLengthObj.seconds)) {
                                        tmp.push(channelTree[selectedChannel][selectedSpotType].dayparts[
                                            selectedDaypart].spotLengths[spotLength]);
                                        tmpIds.add(spotLengthObj.seconds);
                                    }
                                }
                            }
                        }
                    });
                });
            });
            return of(tmp.sort((a, b) => a.seconds > b.seconds ? 1 : -1));
        }
        return this.getSpotLengths(lookupParams);
    }

    getSpotLengths(
        lookupParams: SpotLengthLookupParams
    ): Observable<ConstraintMix[]> {
        const params: any = {};

        if (lookupParams.channelIds) {
            params.channelIds = lookupParams.channelIds;
        }
        if (lookupParams.spotTypeIds) {
            params.spotTypeIds = lookupParams.spotTypeIds;
        }
        if (lookupParams.daypartIds) {
            params.daypartIds = lookupParams.daypartIds;
        }
        if (lookupParams.scenarioId) {
            params.scenarioId = lookupParams.scenarioId;
        }
        if (lookupParams.proposalLineId) {
            params.proposalLineId = lookupParams.proposalLineId;
        }
        params.screenName = lookupParams.screenName;
        return this.generateCachedGet<LookupItem>(
            lookupParams.clearCache,
            UrlStore.api.lookups.spotLengths,
            params
        );
    }

    // TODO: load from API
    getClientLogo(clearCache: boolean = false): Observable<ClientLogo> {
        const img = new Image();
        img.src = 'assets/images/sirius_logo.png';
        return of({image: img, height: 424, width: 2230});
    }

    private generateCachedGet<T>(clearCache: boolean, url: string, params?: any): Observable<T[]> {
        return generateCachedGet<T[]>(clearCache, url, this.http, params, this.cacheData);
    }

    private generateUncachedGet<T>(url: string, params?: any): Observable<T[]> {
        return generateUncachedGet<T[]>(url, this.http, params);
    }

    saveFilter(filterName: string, filter: any) {
        localStorage.setItem(filterName, JSON.stringify(filter));
    }

    getSavedFilter<T>(filterName: string): T {
        return JSON.parse(localStorage.getItem(filterName));
    }

    getPlanDesignations(filterParams: FilterParams, clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache, UrlStore.api.lookups.planDesignations, filterParams);
    }

    getAprrovalType(clearCache: boolean = false): any {
        return ['Soft Approval', 'Auto Approval'];
    }

    getInventoryType(clearCache: boolean = false): any {
        return ['Inventory Exists'];
    }

    getPostBuyAnalysisAdvertisers(filterParams: FilterParams, clearCache: boolean = false): Observable<AdvertiserItem[]> {
        return this.generateCachedGet<AdvertiserItem>(clearCache, UrlStore.api.postBuyAnalysis.advertisers, filterParams);
    }

    getPostBuyAnalysisAgency(filterParams, clearCache: boolean = false): Observable<AdvertiserItem[]> {
        return this.generateCachedGet<AdvertiserItem>(clearCache, UrlStore.api.postBuyAnalysis.agency, filterParams);
    }

    getPostBuyAnalysisOrders(filterParams, clearCache: boolean = false): Observable<AdvertiserItem[]> {
        return this.generateCachedGet<AdvertiserItem>(clearCache, UrlStore.api.postBuyAnalysis.order, filterParams);
    }
}


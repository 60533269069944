import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NavComponent} from './components/nav/nav.component';
import {AccessDeniedComponent} from './components/access-denied/access-denied.component';
import {AppMaterialModule} from '../app-material.module';
import {FooterComponent} from './components/footer/footer.component';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {ToggleButtonComponent} from './components/toggle-button/toggle-button.component';
import {DateSelectComponent} from './components/date-select/date-select.component';
import {CheckboxCellComponent} from './components/checkbox-cell/checkbox-cell.component';
import {FilterSetComponent} from './components/filter-set/filter-set.component';
import {FilterInputComponent} from './components/filter-input/filter-input.component';
import {NotificationComponent} from './components/notification/notification.component';
import {ConstraintMixComponent} from './components/constraint-mix/constraint-mix.component';
import {ConstraintMixLineComponent} from './components/constraint-mix-line/constraint-mix-line.component';
import {DateRangeSelectComponent} from './components/date-range-select/date-range-select.component';
import {ConstraintMixInlineComponent} from './components/constraint-mix-inline/constraint-mix-inline.component';
import {SidenavComponent} from './components/sidenav/sidenav.component';
import {DialogContainerComponent} from './components/dialog-container/dialog-container.component';
import {ExpansionPanelComponent} from './components/expansion-panel/expansion-panel.component';
import {DisplayTextPipe} from './pipes/display-text/display-text.pipe';
import {AgGridHeaderComponent} from './components/ag-grid-header/ag-grid-header.component';
import {DateSelectCellComponent} from './components/date-select-cell/date-select-cell.component';
import {FindPipe} from './pipes/find/find.pipe';
import {SelectFilterCellComponent} from './components/select-filter-cell/select-filter-cell.component';
import {DynamicPipe} from './pipes/dynamic/dynamic.pipe';
import {NoCommaPipe} from './pipes/noComma/no-comma.pipe';
import {FilterWidgetComponent} from './components/filter-widget/filter-widget.component';
import {SelectFilterCellDaypartComponent} from '@shared/components/select-filter-cell-daypart/select-filter-cell-daypart.component';
import {RadioButtonCellComponent} from './components/radio-button-cell/radio-button-cell.component';
import {EditMetricComponent} from './components/edit-metric/edit-metric.component';
import {SelectFilterCellEditorComponent} from './components/select-filter-cell-editor/select-filter-cell-editor.component';
import {
    SelectFilterCellDaypartEditorComponent
} from './components/select-filter-cell-daypart-editor/select-filter-cell-daypart-editor.component';
import {TextDialogComponent} from './components/text-dialog/text-dialog.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {NotifyDialogComponent} from '@shared/components/notify-dialog/notify-dialog.component';
import {FilterWidgetDaypartComponent} from '@shared/components/filter-widget-daypart/filter-widget-daypart.component';


@NgModule({
    declarations: [
        NavComponent,
        AccessDeniedComponent,
        FooterComponent,
        ToggleButtonComponent,
        CheckboxCellComponent,
        DateSelectComponent,
        DateRangeSelectComponent,
        FilterInputComponent,
        FilterSetComponent,
        NotificationComponent,
        ConstraintMixComponent,
        ConstraintMixLineComponent,
        ConstraintMixInlineComponent,
        SidenavComponent,
        DialogContainerComponent,
        ExpansionPanelComponent,
        DisplayTextPipe,
        AgGridHeaderComponent,
        DateSelectCellComponent,
        FindPipe,
        SelectFilterCellComponent,
        SelectFilterCellDaypartComponent,
        DynamicPipe,
        NoCommaPipe,
        FilterWidgetComponent,
        FilterWidgetDaypartComponent,
        RadioButtonCellComponent,
        EditMetricComponent,
        SelectFilterCellEditorComponent,
        SelectFilterCellDaypartEditorComponent,
        TextDialogComponent,
        ConfirmDialogComponent,
        NotifyDialogComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        AppMaterialModule,
        FormsModule,
        ReactiveFormsModule
    ],
    entryComponents: [
        CheckboxCellComponent,
        DateSelectCellComponent,
        SelectFilterCellComponent,
        SelectFilterCellDaypartComponent,
        NotificationComponent,
        AgGridHeaderComponent,
        FilterWidgetComponent,
        FilterWidgetDaypartComponent,
        RadioButtonCellComponent
    ],
    exports: [
        NavComponent,
        FooterComponent,
        AccessDeniedComponent,
        ToggleButtonComponent,
        CheckboxCellComponent,
        RadioButtonCellComponent,
        DateSelectComponent,
        DateRangeSelectComponent,
        FilterInputComponent,
        FilterSetComponent,
        NotificationComponent,
        ConstraintMixComponent,
        ConstraintMixLineComponent,
        ConstraintMixInlineComponent,
        SidenavComponent,
        DialogContainerComponent,
        ExpansionPanelComponent,
        DisplayTextPipe,
        DateSelectCellComponent,
        SelectFilterCellComponent,
        SelectFilterCellDaypartComponent,
        FindPipe,
        DynamicPipe,
        NoCommaPipe,
        EditMetricComponent
    ],
})
export class SharedModule {
}

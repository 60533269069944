export const environment = {
    production: false,
    auth: {
        clientID: 'Z3lr6QnjtutgsrpJ5PLtezaIh23KeUvd',
        audience: 'https://media.ra.dev',
        domain: 'media-dev.auth0.com',
        algorithm: 'RSA256',
        responseType: 'token id_token',
        scope: 'openid profile email'
    },
    api: 'https://api-phoenix-test.media-test.revenueanalytics.io',
    sfOpportunityUrl: 'https://pandora--pandora1.lightning.force.com/lightning/r/Opportunity/OPPORTUNITY_ID/view'
};

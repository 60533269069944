import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-dialog-container',
    templateUrl: './dialog-container.component.html',
    styleUrls: ['./dialog-container.component.scss']
})
export class DialogContainerComponent implements OnInit {
    @Input() loading = true;
    @Input() error = false;
    @Input() title: string;
    @Input() displayCloseButton = true;

    @Output() closeEvent = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    closeForm() {
        this.closeEvent.emit();
    }

}

import {
    AdvertiserItem,
    LookupItem,
    StationConstraintMix,
    ConstraintMix,
    DateRange,
    Grid,
    GroupedGrid,
    PairedLookupItem,
    NumericLookupItem,
    ChannelConstraintMix,
    GridFormat,
    FlightRange, SurveyLookupItem
} from '@models/lookup';
import {ProposalInputFilter, ProposalOutputFilter} from '@models/filter';
import {ColDef} from 'ag-grid-community';
import {DateRangeFilter, MultiSelectFilter} from '@models/filter-types';

export const OPT_STATUS_SUCCEEDED = 'SUCCEEDED';
export const OPT_STATUS_RUNNING = 'RUNNING';
export const OPT_STATUS_FAILED = 'FAILED';
export const OPT_DISPLAY_STATUS_COMPLETED = 'Completed';
export const OPT_DISPLAY_STATUS_ERROR = 'Error';

export interface RfpSummary {
    proposalId: number;
    advertiser: AdvertiserItem;
    agency: LookupItem;
    agencyCommission: number;
    campaignName: string;
    primaryAE: LookupItem;
    primaryRegion: string;
    createdBy: string;
    additionalAEs: LookupItem[];
    primaryReviewer?: LookupItem;
    planningMember: LookupItem[];
    salesCoordinators: LookupItem[];
    closeProbability: NumericLookupItem;
    createdDate: string;
    expirationDate: string;
    status: string;
    clientDeadlineDate: string;
    isLocked: boolean;
    canUpdateLocked?: boolean;
    scenarios: ProposalScenario[];
    scenarioCount?: number;
    isGlobalTemplate?: boolean;
    buyType: LookupItem;
    planDesignations: LookupItem[];
    salesforceOppID: string;
    unmappedAdvertiser: AdvertiserItem;
    unmappedAgency: LookupItem;
}

export interface ProposalScenario {
    status: LookupItem;
    isPrimary: boolean;
    isLocked: boolean;
    createdBy: LookupItem;
    createdDate: string;
    name: string;
    displayName?: string;
    id: number;
    proposalId: number;
    order?: number;
    approvedBy?: LookupItem;
    approvedDate?: Date;
    rejectedBy?: LookupItem;
    rejectedDate?: Date;
}

export interface ProposalComment {
    createdDate: Date;
    createdBy: string;
    comment: string;
    channel?: string;
    daypart?: string;
}

export interface ProposalLineComment {
    createdDateTime: Date;
    createdBy: string;
    comment: string;
}

export interface OptStatusHistory {
    optimizationUUID: string;
    optimizationDatetime: Date;
    stepFunctionStatus: 'SUCCEEDED' | 'RUNNING' | 'FAILED';
    status: OptimizationStatus;
    errorMessage: string;
}

export interface ScenarioInputs {
    pricingModel: LookupItem;
    categories: LookupItem[];
    exclude: LookupItem;
    flightDateRange: DateRange;
    flightWeeks: LookupItem[];
    ratingsDemo: LookupItem;
    secondaryRatingsDemo: LookupItem;
    tertiaryRatingsDemo: LookupItem;
    surveys: SurveyLookupItem[];
    optimizationPriority: LookupItem;
    optStatusLatest?: OptStatusHistory;
    displayOptStatus?: string;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
    status: LookupItem;

    isLocked: boolean;
    scenarioId: number;
    scenarioName: string;

    markets: ScenarioMarketInputs[];

    scenarioCount: number;
}

export interface ScenarioMarketInputs {
    market: LookupItem;
    budget: number;
    budgetIsNet: boolean;
    metric: PairedLookupItem;
    dayparts: ConstraintMix[];
    spotLengths: ConstraintMix[];
    channelCategoryAllocation: LookupItem;
    stations: StationConstraintMix[];
    channels: ChannelConstraintMix[];
    categories: ConstraintMix[];
    bonusSpots: number;
    metricValue: number;
}

export interface ScenarioOutputSummary {
    flightDateRange: FlightRange;
    weekCount: number;
    budgetIsNet: boolean;
    keyMetrics: {
        target: {
            budget: number,
            spotCount: number,
            grossRate: number
        },
        actual: {
            budget: number,
            spotCount: number,
            grossRate: number,
            grossRateNoBonus: number,
            bonusSpotCount: number
        }
    };
    currentPrimaryDemo: {
        demographic: LookupItem,
        impressions: number,
        cpm: number
    };
    demographicActuals: SummaryDemoActuals[];
    overBonusThreshold?: boolean;
    ratingsUpdated?: boolean;
    recentlyAccessed?: string[];
}

export interface SummaryDemoActuals {
    demographic: LookupItem;
    impressions?: number;
    cpm?: number;
    reach?: number;
    frequency?: number;
}

export interface EdisonRefreshStatus {
    timeStarted: string;
    status: string;
    lastRun: string;
}

export interface EdisonPollData {
    status: EdisonRefreshStatus;
    summaryActuals: SummaryDemoActuals[];
}

export interface ScenarioKeyMetrics {
    budget: number;
    spotCount: number;
    spotRate: number;
    format?: GridFormat;
}

export interface ScenarioComparisonGrid {
    comparisonGrid: Grid<ScenarioComparison>;
    targetComparisonGrid: GroupedGrid<ScenarioComparison>;
}

export interface ScenarioComparison {
    proposalId: number;
    scenarioName: string;
    scenarioId: number;
    createdBy: string;
    createdDate: Date;
    demographic: string;
    totalRevenueTarget: number;
    totalRevenueActual: number;
    totalRevenuePercentToTarget: number;
    totalSpotsTarget: number;
    totalSpotsActual: number;
    totalSpotsPercentToTarget: number;
    cpmTarget: number;
    cpmActual: number;
    cpmPercentToTarget: number;
    impressionsTarget: number;
    impressionsActual: number;
    impressionsPercentToTarget: number;
    averageSpotTarget: number;
    averageSpotActual: number;
    averageSpotPercentToTarget: number;
}

export interface ScenarioSummaryRow {
    type: string;
    budget: number;
    spotCount: number;
    grossRate: number;
    grossRateNoBonus: number;
    primaryImpressions?: number;
    primaryCpm?: number;
    primaryReach?: number;
    primaryFrequency?: number;
    secondaryImpressions?: number;
    secondaryCpm?: number;
    secondaryReach?: number;
    secondaryFrequency?: number;
    tertiaryImpressions?: number;
    tertiaryCpm?: number;
    tertiaryReach?: number;
    tertiaryFrequency?: number;
}

export interface FullOutputRefresh {
    scenarioSummary: ScenarioOutputSummary[];
    scenarioProposal: any;
    scenarioAnalysis: any;
}

export interface UpdateProposal {
    Subtotals: any;
    'Line Details By Week': any;
}

export interface OptimizationStatus extends LookupItem {
    name: 'No History' | 'Generating Proposal' | 'Completed' | 'Error';
}

export interface ProposalUpdating {
    msg: string;
    isUpdatingProposal: boolean;
    isCreatingScenario: boolean;
    isMakingPrimary: boolean;
    isCopyingScenario: boolean;
    isSavingScenario: boolean;
    isDeletingScenario: boolean;
    isSubmittingScenario: boolean;
    isApprovingScenario: boolean;
    isRejectingScenario: boolean;
    isClosingProposal: boolean;
    isSubmittingRunOpt: boolean;
    isExportingToExcel: boolean;
    isExportingToPdf: boolean;
    isSendingToWideOrbit: boolean;
    isRefreshingRatings: boolean;
    isRefreshingRatingsEdison: boolean;
    isLoadingInputs: boolean;
    isLoadingOutputs: boolean;
    isExportingToXml: boolean;
    isExportingToAgencyXml: boolean;
    error: boolean;
    isCloningProposal: boolean;
    isOptimizing: boolean;
}

export interface ScenarioSave {
    isInputChanged?: boolean;
    isOutputChanged?: boolean;
    inputs?: ProposalInputFilter;
    outputs?: ProposalOutputFilter;
    hasFlightShifted: boolean;
    resetOverrides: string;
}

export interface ProposalExportOptions {
    exportType: 'pdf' | 'excel' | 'xml';
    proposalOptions: ColDef[];
    summaryMetricOptions: ProposalExportSummaryOptions;
    analysisOptions: Grid<any>[];
    revenueTotals: string[];
    splitByQuarters?: boolean;
    snapshotTotals: Snapshot[];
}

export interface ProposalExportSummaryOptions {
    primaryDemo: {
        demo: LookupItem;
        options: string[];
    };
    secondaryDemo: {
        demo: LookupItem;
        options: string[];
    };
    tertiaryDemo: {
        demo: LookupItem;
        options: string[];
    };
    budgetIsNet?: boolean;
}

export interface EditFlightDialogData {
    flightRangeFilter: DateRangeFilter;
    flightWeeksFilter: MultiSelectFilter<LookupItem>;
    isChanged?: boolean;
    isShift?: boolean;
}

export interface AverageSpotsWeekDialogData {
    newSpotPerWeekValue: number;
}

export interface ProposalData {
    gridData: Grid<any>[];
    snapshotHistory: {
        snapshots: Snapshot[]
    };
}

export interface Snapshot {
    auditAction: string;
    createdBy: string;
    createdDate: string;
    scenarioId: number;
    snapshotId: number;
    name: string;
    grossRevenue: number;
}

export interface ProposalDifferences {
    added: Grid<any>;
    deleted: Grid<any>;
    updated: Grid<any>;
}

export interface ApiProposalData {
    gridData: ProposalDifferences;
    grossRevenueDiff: number;
    snapshotHistory: {
        snapshots: Snapshot[]
    };
}

export interface CustomDaypartUpdate {
    id: number;
    name: string;
}

export interface BulkCustomDaypartUpdate {
    id: number;
    name: string;
    originalIds: Set<number>;
    customDaypartIds: number[];
}

export interface BulkCustomDaypartReturn {
    id: number;
    name: string;
    originalIds: number [];
    customDaypartIds: number[];
}

export interface MergedProposalLineDetails {
    gridData: Grid<any>[];
}

import {BrowserModule, Title} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AuthGuard } from 'src/app/core/guards/auth/auth.guard';
import { TokenInterceptor } from '@services/token.interceptor';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './app-material.module';
import { UrlStore } from '@shared/helpers/url-store';
import { AccessDeniedComponent } from '@shared/components/access-denied/access-denied.component';
import { SharedModule } from '@shared/shared.module';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./main/main.module').then(mod => mod.MainModule)
    },
    {
        path: UrlStore.ui.login,
        loadChildren: () =>
            import('./login/login.module').then(mod => mod.LoginModule)
    },
    {
        path: UrlStore.ui.radio.proposalPro,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./proposal-opt/radio/radio-proposal-opt.module')
                .then(mod => mod.RadioProposalOptModule)
    },
    {
        path: UrlStore.ui.radio.admin,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./admin/radio/radio-admin.module').then(mod => mod.RadioAdminModule)
    },
    {
        path: UrlStore.ui.tv.admin,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./admin/tv/tv-admin.module').then(mod => mod.TvAdminModule)
    },
    {
        path: UrlStore.ui.siteDown,
        pathMatch: 'full',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./maintenance/site-down.module').then(mod => mod.SiteDownModule)
    },
    {
        path: UrlStore.ui.accessDenied,
        canActivate: [AuthGuard],
        component: AccessDeniedComponent
    },
    {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./not-found/not-found.module').then(mod => mod.NotFoundModule)
    }
];

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppMaterialModule,
        HttpClientModule,
        SharedModule,
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    ],
    exports: [
        RouterModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        Title
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}

<div class="ra-card">
    <div class="ra-card-header level is-full-width">
        <div class="level-left">
            <div class="ra-card-header-title level-item">
                <i *ngIf="!isLoading" class="fas fa-clipboard-list i-ra-blue is-size-5"></i>
                <img *ngIf="isLoading" src="assets/images/loader.svg" class="is-size-5"/>
                <span>Proposal Summary</span>
            </div>
        </div>
        <div class="level-right summary-button-row">
            <div *ngIf="!isLoading" class="level-item">
                <span class="button is-link" (click)="[isExpanded=!isExpanded, expandAllProposalsScenarios()]">
                    <i *ngIf="!isExpanded" class="fas fa-fw fa-angle-right"></i>
                    <i *ngIf="isExpanded" class="fas fa-fw fa-angle-down"></i> Expand All
                </span>
            </div>
            <div *ngIf="config.featureFlag || config.userRoleId == planningMemberUserRoleId" class="level-item">
                        <span class="button is-link" (click)="goToLaunch()">
                            <i class="fas fa-fw fa-plus"></i> New Proposal
                        </span>
            </div>
            <div class="level-item">
                <span *ngIf="!isLoading" class="button is-link" mat-button
                      [matMenuTriggerFor]="exportOptions"
                      [attr.disabled]="rowData?.length == 0 ? true : null"
                      title="{{rowData?.length == 0 ? 'You must have at least one proposal to be exported' : null}}">
                    <i class="fas fa-fw fa-file-export"></i>Export<i class="fas fa-fw fa-caret-down"></i>
                </span>
                <mat-menu #exportOptions>
                    <div class="dropdown-buttons">
                        <span class="button is-link" (click)="exportToExcel()"
                              [attr.disabled]="rowData?.length == 0 ? true : null"
                              title="{{rowData?.length == 0 ? 'You must have at least one proposal to be exported' : null}}">
                            <i class="fas fa-file-excel" aria-hidden="true"></i>
                            Excel
                        </span>
                        <span class="button is-link" (click)="exportToPdf()"
                              [attr.disabled]="rowData?.length == 0 ? true : null"
                              title="{{rowData?.length == 0 ? 'You must have at least one proposal to be exported' : null}}">
                            <i class="fas fa-file-pdf" aria-hidden="true"></i>
                            PDF
                        </span>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>
    <div *ngIf="!isLoading" class="ra-card-content">
        <ag-grid-angular
            class="ag-theme-material summary-table"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [autoGroupColumnDef]="autoGroupColumnDef"
            [rowData]="rowData"
            [treeData]="true"
            [groupDefaultExpanded]="groupDefaultExpanded"
            [getDataPath]="getDataPath"

            (gridReady)="onGridReadyAutoSizeSelectedColumn($event)"
            (gridColumnsChanged)="gridColumnApi?.autoSizeAllColumns()"
            (sortChanged)="gridApi?.refreshCells()"
            [gridOptions]="gridOptions"
            rowSelection="single"
            [suppressContextMenu]="true"
        >
        </ag-grid-angular>
    </div>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomDateAdapter} from '@shared/helpers/custom-date-adapter';
import {Platform, PlatformModule} from '@angular/cdk/platform';
import {SatDatepickerModule, SatNativeDateModule} from 'saturn-datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter} from '@angular/material/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';

const MATERIAL_MODULES = [
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatCardModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTabsModule,
    MatRadioModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatExpansionModule,
    MatSlideToggleModule
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        PlatformModule,
        ...MATERIAL_MODULES
    ],
    providers: [
        {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform]}
    ],
    exports: [
        PlatformModule,
        ...MATERIAL_MODULES
    ]
})
export class AppMaterialModule {
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlStore } from '@shared/helpers/url-store';
import { Observable, of } from 'rxjs';
import { ApiConfig, UiConfig, UiProductConfig } from '@models/config';
import {switchMap} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    constructor(private http: HttpClient) {
    }

    getConfig(): Observable<UiConfig> {
        return this.http.get<ApiConfig>(UrlStore.api.config).pipe(
            switchMap((apiConfig: ApiConfig) => {
                const userName = apiConfig.userName;
                const customerId = apiConfig.customerId;
                const userRoleId = apiConfig.userRoleId;
                const featureFlag = apiConfig.featureFlag;
                const createAdvertiserAgencyFlag = apiConfig.createAdvertiserAgencyFlag;

                const customerConfig: UiProductConfig = {};
                apiConfig.customerConfig.forEach(product => {
                    customerConfig[product.name] = {isEnabled: product.isEnabled};
                    product.modules.forEach(module => {
                        customerConfig[product.name][module.name] = {isEnabled: module.isEnabled};
                        module.capabilities.forEach(capability => {
                            customerConfig[product.name][module.name][capability.name] = capability.isEnabled;
                        });
                    });
                });

                const roleConfig: UiProductConfig = {};
                apiConfig.userRoleConfig.forEach(product => {
                    roleConfig[product.name] = {isEnabled: product.isEnabled};
                    product.modules.forEach(module => {
                        roleConfig[product.name][module.name] = {isEnabled: module.isEnabled};
                        module.capabilities.forEach(capability => {
                            roleConfig[product.name][module.name][capability.name] = capability.isEnabled;
                        });
                    });
                });

                return of({ userName, customerId, userRoleId, featureFlag, customerConfig, roleConfig, createAdvertiserAgencyFlag});
            })
        );
    }

    getZendeskToken(): Observable<{ zendeskJwt: string }> {
        return this.http.get<{ zendeskJwt: string }>(UrlStore.api.zendeskToken);
    }
}

import {AgGridHeaderComponent} from '@shared/components/ag-grid-header/ag-grid-header.component';
import {GridColumn, GridFormat, GroupedGridColumn} from '@models/lookup';
import {currencyFormatter, numberFormatter, percentFormatter, dateFormatter, getDisplayText,
    SALES_COORDINATORS_COLUMN_FIELD} from './helpers';
import {
    ColGroupDef,
    ColDef,
    ICellRendererParams,
    CellClassParams,
    ValueSetterParams,
    ValueGetterParams,
    ColSpanParams,
} from 'ag-grid-community';
import {ExcelStyle, ITooltipParams} from 'ag-grid-community';


const FORMAT_TYPES = {
    currency: currencyFormatter,
    number: numberFormatter,
    percent: percentFormatter,
    date: dateFormatter,
    string: (val, _ = null) => val,
};

const ONE_DECIMAL_FORMAT = '1.0-0';


export const excelStyles: ExcelStyle[] = [
    {
        id: 'header',
        interior: {
            color: '#101D91',
            pattern: 'Solid',
            patternColor: undefined
        },
        font: {
            bold: true,
            color: '#ffffff',
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined
        },
        alignment: {
            horizontal: 'Center',
            vertical: undefined,
            verticalText: undefined,
            indent: undefined,
            readingOrder: undefined,
            shrinkToFit: undefined,
            rotate: undefined,
            wrapText: undefined
        },
        borders: undefined,
        numberFormat: undefined,
        protection: undefined
    },
    {
        id: 'ra-lightest-gray',
        numberFormat: undefined,
        interior: {
            color: '#F2F2F2',
            pattern: 'Solid',
            patternColor: undefined
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined
    },
    {
        id: 'white-background',
        numberFormat: undefined,
        interior: {
            color: '#FFFFFF',
            pattern: 'Solid',
            patternColor: undefined
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined
    },
    {
        id: 'white-background-key',
        font: {
            bold: true,
            color: undefined,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined
        },
        numberFormat: undefined,
        interior: {
            color: '#FFFFFF',
            pattern: 'Solid',
            patternColor: undefined
        },
        alignment: undefined,
        borders: undefined,
        protection: undefined
    },
    {
        id: 'ra-faded-blue',
        numberFormat: undefined,
        interior: {
            color: '#d6cee9',
            pattern: 'Solid',
            patternColor: undefined
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined
    },
    {
        id: 'currency-1.2-class',
        numberFormat: {format: '$#,##0.00_);($#,##0.00)'},
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined
    },
    {
        id: 'currency-1.0-0-class',
        numberFormat: {format: '$#,##0_);($#,##0)'},
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined
    },
    {
        id: 'percent-1.2-2-class',
        numberFormat: {format: '0.00%'},
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined
    },
    {
        id: 'percent-1.0-0-class',
        numberFormat: {format: '0%'},
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined
    },
    {
        id: 'percent-1.0-1-class',
        numberFormat: {format: '0.0%'},
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined
    },
    {
        id: 'number-1.2-2-class',
        numberFormat: {format: '0.00'},
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined
    },
    {
        id: 'number-1.0-0-class',
        numberFormat: {format: '0'},
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined
    },
    {
        id: 'gray-background-key',
        font: {
            bold: true,
            color: undefined,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined
        },
        numberFormat: undefined,
        interior: {
            color: '#F2F2F2',
            pattern: 'Solid',
            patternColor: undefined
        },
        alignment: undefined,
        borders: undefined,
        protection: undefined
    },
    {
        id: 'gray-background-key-across',
        font: {
            bold: true,
            color: undefined,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined
        },
        numberFormat: undefined,
        interior: {
            color: '#F2F2F2',
            pattern: 'Solid',
            patternColor: undefined
        },
        alignment: {
            horizontal: 'Right',
            wrapText: false,
            vertical: undefined,
            indent: undefined,
            verticalText: undefined,
            shrinkToFit: undefined,
            readingOrder: undefined,
            rotate: undefined
        },
        borders: undefined,
        protection: undefined,

    },
    {
        id: 'gray-background',
        font: undefined,
        numberFormat: undefined,
        interior: {
            color: '#F2F2F2',
            pattern: 'Solid',
            patternColor: undefined
        },
        alignment: {
            wrapText: false,
            vertical: undefined,
            indent: undefined,
            verticalText: undefined,
            shrinkToFit: undefined,
            horizontal: undefined,
            readingOrder: undefined,
            rotate: undefined
        },
        borders: undefined,
        protection: undefined
    },
    {
        id: 'justify-center',
        font: undefined,
        numberFormat: undefined,
        interior: undefined,
        alignment: {
            wrapText: false,
            vertical: undefined,
            indent: undefined,
            verticalText: undefined,
            shrinkToFit: undefined,
            horizontal: 'Center',
            readingOrder: undefined,
            rotate: undefined
        },
        borders: undefined,
        protection: undefined
    },
    {
        id: 'cell-renderer-framework',
        font: undefined,
        numberFormat: undefined,
        interior: undefined,
        alignment: {
            wrapText: false,
            vertical: undefined,
            indent: undefined,
            verticalText: undefined,
            shrinkToFit: undefined,
            horizontal: 'Left',
            readingOrder: undefined,
            rotate: undefined
        },
        borders: undefined,
        protection: undefined
    }
];

export const TOTAL_ROW_ID = -1;
export const TOTAL_WEEKLY_ROW_ID = -2;

export function getGroupedColumnDefs(groupedColumns: GroupedGridColumn[], customCellRenderers: any = {}): ColGroupDef[] {
    const colGroupDef: ColGroupDef[] = [];
    groupedColumns.forEach((groupedColumn, index) => {
        const colDefs: ColDef[] = getColumnDefs(groupedColumn.columns, customCellRenderers);
        colGroupDef.push({
            headerName: groupedColumn.name,
            children: colDefs,
            groupId: index.toString()
        });
    });
    return colGroupDef;
}

export function getColumnName(column: GridColumn): string {
    if (column.headerFormat && column.headerFormat.type === 'date') {
        return FORMAT_TYPES[column.headerFormat.type](new Date(column.name), column.headerFormat.format);
    }

    return column.name;
}

export function getColumnDefs(columns: GridColumn[], customCellRenderers: any = {}, isLocked: boolean = false, tooltipCols = {},
                              isCustomWidth: boolean = false): ColDef[] {
    const colDefs: ColDef[] = [];

    columns.forEach(column => {

        const customCellRenderer = customCellRenderers[column.field];
        let cellRendererFramework;
        let cellEditorFramework;
        let cellRendererParams;
        let cellEditorParams;

        if (customCellRenderer) {
            cellRendererFramework = customCellRenderer.cellRendererFramework;
            cellEditorFramework = customCellRenderer.cellEditorFramework;
            cellRendererParams = cellRendererFramework ? {
                ...customCellRenderer.params,
                isEditable: customCellRenderer.params?.isEditable === undefined
                    ? column.isEditable && !isLocked
                    : customCellRenderer.params?.isEditable
            } : undefined;
            cellEditorParams = cellEditorFramework ? {
                ...customCellRenderer.params,
                isEditable: customCellRenderer.params?.isEditable === undefined
                    ? column.isEditable && !isLocked
                    : customCellRenderer.params?.isEditable
            } : undefined;
        }
        const cellRenderer = !cellRendererFramework
            ? (params: ICellRendererParams) => {
                return params.data?.format
                    ? columnRenderer(params, params.data.format)
                    : column.format
                        ? columnRenderer(params, column.format)
                        : columnRenderer(params, null);
            }
            : null;
        const colDef: ColDef =  {
            headerComponentFramework: AgGridHeaderComponent,
            headerComponentParams: {
                name: getColumnName(column),
                icon: column.isEditable && !isLocked ? 'fas fa-edit' : '',
                editable: column.isEditable,
                formatType: column.format?.type
            },
            field: column.field,
            hide: column.hide,
            editable: (params) => editable(params, column, cellRendererFramework, cellEditorFramework, isLocked),
            cellEditorFramework: cellEditorFramework ? cellEditorFramework : undefined,
            cellEditorParams: cellEditorFramework ? cellEditorParams : undefined,
            cellRenderer,
            cellRendererFramework,
            cellRendererParams,
            columnGroupShow: column.columnGroupShow,
            comparator: (column.format?.type === 'string') ? caseInsensitiveComparator : undefined,
            valueSetter,
            valueGetter,
            colSpan: (params: ColSpanParams) =>
                params.data && params.data[params.colDef.field] && params.data[params.colDef.field].colSpan
                    ? params.data[params.colDef.field].colSpan
                    : 1,
            cellClass: column.format
                ? (params: CellClassParams) => cellClass(params, column.format)
                : (params: CellClassParams) => {
                    return params.data?.format
                        ? cellClass(params, params.data.format)
                        : cellClass(params, undefined);
                },
            cellClassRules: cellClassRules(),
            tooltipValueGetter: (params: ITooltipParams) => {
                let tooltip: string | string [] =
                    params.data[params.colDef.field]?.displayTooltip;
                tooltip = !tooltip ? params.data.displayTooltip : tooltip;
                tooltip = typeof tooltip === 'string' ?
                    tooltip : (tooltip != null ? tooltip.join(' ') : null);  // Intentionally getting everything null-like
                return tooltip;  // Intentionally getting everything null-like
            },
            width: column.field === SALES_COORDINATORS_COLUMN_FIELD ? 110 : isCustomWidth ? 90 : 30
        };
        if (tooltipCols[colDef.field]) {
            colDef.tooltipField = colDef.field;
        }
        colDefs.push(colDef);
    });
    return colDefs;
}

export function valueSetter(params: ValueSetterParams): boolean {
    const data = params.data[params.colDef.field];
    if (!data) {
        return params.data[params.colDef.field] = params.newValue;
    }
    if (data.hasOwnProperty('value')) {
        if (data.value.hasOwnProperty('current')) {
            return data.value.current = params.newValue;
        } else if (data.value.hasOwnProperty('new')) {
            return data.value.new = params.newValue;
        }
    } else if (data.hasOwnProperty('current')) {
        return data.current = params.newValue;
    } else if (data.hasOwnProperty('new')) {
        return data.new = params.newValue;
    }
    return params.data[params.colDef.field] = params.newValue;
}

export function valueGetter(params: ValueGetterParams): string | number {
    let dataValue;
    if (params.data && params.data[params.colDef.field] !== null && params.data[params.colDef.field] !== undefined) {
        if (params.data[params.colDef.field].hasOwnProperty('value')) {
            dataValue = params.data[params.colDef.field].value;
        } else {
            dataValue = params.data[params.colDef.field];
        }
        if (dataValue) {
            if (dataValue.hasOwnProperty('current')) {
                return dataValue.current;
            } else if (dataValue.hasOwnProperty('new')) {
                return dataValue.new;
            } else if (dataValue.hasOwnProperty('name')) {
                return getDisplayText(dataValue);
            }
        }
    }
    return dataValue;
}

export function columnRenderer(params: ICellRendererParams, format: GridFormat): string {
    let rendererValue;
    let dataValue;
    if (params.data && params.data[params.colDef.field] && params.data[params.colDef.field].hasOwnProperty('value')) {
        rendererValue = params.value;
        dataValue = params.data[params.colDef.field].value;
    } else {
        rendererValue = params.value;
        dataValue = params.data ? params.data[params.colDef.field] : undefined;
    }
    if (
        typeof dataValue === 'string' && isNaN(Number(dataValue)) && params.colDef.headerComponentParams.formatType !== 'date') {
        return dataValue;
    }
    if (format && format.format) {
        if (
            dataValue &&
            (
                dataValue.original
                || dataValue.original === 0
            )
        ) {
            const original = Number(dataValue.original);
            const current = Number(rendererValue);
            const formatOriginal = FORMAT_TYPES[format.type](
                getFormatValue(original, format.type) as any, ONE_DECIMAL_FORMAT);
            const formatCurrent = FORMAT_TYPES[format.type](
                getFormatValue(current, format.type) as any,
                 current % 1 === 0 ? ONE_DECIMAL_FORMAT : format.format);
            if ((original || original === 0) && original !== current) {
                const overrideClass: string =
                    original < current
                        ? 'higher-override'
                        : 'lower-override';
                return `<span class="overridden-value">${formatOriginal}&rarr;</span>` +
                    `<span class="${overrideClass}">${formatCurrent}</span>`;
            }
            return current || current === 0 || rendererValue === '0'
                ? formatCurrent
                : '';
        }
    }
    return (rendererValue || rendererValue === 0) && format
        ? format.format
            ? FORMAT_TYPES[format.type](
                getFormatValue(rendererValue, format.type),
                params.colDef.field === 'unitRate' && rendererValue % 1 === 0 ?
                    ONE_DECIMAL_FORMAT : format.format)
            : FORMAT_TYPES[format.type](
                getFormatValue(rendererValue, format.type))
        : params.value;
}

export function getFormatValue(value, formatType: string) {
    return formatType === 'percent' ? value / 100 : value;
}

export function cellClass(params: CellClassParams, format: GridFormat): string[] {
    const classes = ['cell-data'];
    if (params.colDef.field === 'type') {
        classes.push('bold-font');
    }

    // These are only used for excel exporting
    if (params.data?.format) {
        classes.push(`${params.data.format.type}-${params.data.format.format}-class`);
    } else {
        classes.push(format ? `${format.type}-${format.format}-class` : 'text');
    }

    if (format && (format.type === 'currency' || format.type === 'percent')) {
        classes.push('justify-flex-end');
        classes.push('padding-right-5-em');
    } else {
        classes.push('justify-center');
    }

    if (params.colDef.field === 'cloneInputs' ||
        params.colDef.field === 'cloneHistory' ||
        params.colDef.field === 'viewProposal') {
        classes.push('button-table-aligner');
    } else if (params.colDef.field === 'comment') {
        classes.push('comment');
    }

    if (params.colDef.cellRendererFramework) {
        classes.push('cell-renderer-framework');
    }

    if (params.data?.id === TOTAL_ROW_ID || params.data?.id === TOTAL_WEEKLY_ROW_ID) {
        classes.push('ra-faded-blue');
    }

    return classes;
}

export function cellClassRules(): { [cssClassName: string]: string | ((params: CellClassParams) => boolean) } {
    const classes = {};
    classes['ra-lightest-gray'] = (params: CellClassParams) => params.node.rowIndex % 2 === 1;
    classes['white-background'] = (params: CellClassParams) => params.node.rowIndex % 2 === 0;
    classes['warn-info'] = (params: CellClassParams) => (params.data[params.colDef.field] &&
        params.data[params.colDef.field].cellWarn === 'info') || params.data.rowWarn === 'info';
    classes['warn-error'] = (params: CellClassParams) => (params.data[params.colDef.field] &&
        params.data[params.colDef.field].cellWarn === 'error') || params.data.rowWarn === 'error';
    classes['warn-no-cap'] = (params: CellClassParams) => {

        if (params.colDef.cellClassRules['invalid-cell'] &&
            (params.colDef.cellClassRules['invalid-cell'] as unknown as (params) => boolean)(params)) {
            return false;
        }

        return (params.data[params.colDef.field] &&
            params.data[params.colDef.field].cellWarn === 'warn') ||
            params.data.rowWarn === 'warn';
    };

    return classes;
}

export function editable(params, column: GridColumn, cellRendererFramework, cellEditorFramework, isLocked: boolean): boolean {
    if (params.data && params.data[params.colDef.field] && params.data[params.colDef.field].hasOwnProperty('isEditable')) {
        return params.data[params.colDef.field].isEditable;
    }
    return column.isEditable
        && (!cellRendererFramework || cellEditorFramework)
        && params.data?.id !== TOTAL_ROW_ID
        && params.data?.id !== TOTAL_WEEKLY_ROW_ID
        && !isLocked;
}

export function caseInsensitiveComparator(valueA, valueB, nodeA, nodeB) {
    valueA = valueA ? valueA : '';
    valueB = valueB ? valueB : '';
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
}

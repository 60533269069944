import {NativeDateAdapter} from '@angular/material/core';

const MONDAY = 1;

/** Adapts the native JS Date for use with cdk-based components that work with dates. */
export class CustomDateAdapter extends NativeDateAdapter {

    getFirstDayOfWeek(): number {
        return MONDAY;
    }
}

import {Component} from '@angular/core';
import {IHeaderAngularComp} from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

@Component({
    selector: 'app-ag-grid-header',
    templateUrl: './ag-grid-header.component.html',
    styleUrls: ['./ag-grid-header.component.scss']
})
export class AgGridHeaderComponent implements IHeaderAngularComp {
    params: any;
    sortIcon = 'fas fa-fw fa-sort';

    constructor() {
    }
    refresh(params: IHeaderParams): boolean {
        return true;
    }

    agInit(params) {
        this.params = params;
        this.updateSortIcon();
    }

    sort() {
        if (this.params.enableSorting) {
            this.params.progressSort();
            this.updateSortIcon();
        }
    }

    updateSortIcon() {
        switch (this.params.column.sort) {
            case 'asc':
                this.sortIcon = 'fas fa-fw fa-arrow-up';
                break;
            case 'desc':
                this.sortIcon = 'fas fa-fw fa-arrow-down';
                break;
            default:
                this.sortIcon = 'fas fa-fw fa-sort';
        }
    }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';
import { UrlStore } from '@shared/helpers/url-store';
import { UiConfig, ProposalProProductConfig, RateOpticsProductConfig, PerformanceAnalyticsProductConfig } from '@models/config';
import {StatusService} from '@services/status/status.service';
import { PLANNING_MEMBER_USER_ROLE_ID } from 'src/app/proposal-opt/shared/summary-grid/summary-grid.component';

@Injectable()
export class AuthGuard implements CanActivate {

    config: UiConfig;

    constructor(
        private auth: AuthService, public router: Router, private statusService: StatusService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isLoggedIn = this.auth.isLoggedIn();
        const isConfigured = this.auth.isConfigured();

        const splitRoute = state.url.split('/');

        if (splitRoute[0] === '') {
            splitRoute.splice(0, 1);
        }

        if (splitRoute[0].startsWith(UrlStore.ui.siteDown)) {
            if (!isLoggedIn) {
                if (splitRoute[0] === UrlStore.ui.siteDown) {
                    this.router.navigate([UrlStore.ui.siteDown], {queryParams: {noLogin: true}});
                    return false;
                }
                return true;
            }
        }

        if (!isLoggedIn || !isConfigured) {
            this.statusService.siteHeartbeat().subscribe((succ) => {
                this.auth.beginLogin(state.url.split('?')[0], route.queryParams);
            });
            return false;
        } else {

            let canActivate = false;
            this.config = this.auth.getConfig();
            switch (splitRoute[0]) {
                case 'radio': {
                    canActivate = this.canActivateRadio(splitRoute.slice(1, splitRoute.length));
                    break;
                }
                case 'tv': {
                    canActivate = this.canActivateTv(splitRoute.slice(1, splitRoute.length));
                    break;
                }
                case 'login': {
                    canActivate = true;
                    break;
                }
                case 'accessDenied': {
                    canActivate = true;
                    break;
                }
                case 'site-down': {
                    canActivate = true;
                    break;
                }
                case '': {
                    canActivate = true;
                    break;
                }
            }
            if (!canActivate) {
                this.router.navigate([UrlStore.ui.accessDenied]);
                return false;
            } else {
                return true;
            }
        }
    }

    canActivateRadio(url: string[]): boolean {
        // If the user doesn't have access to any radio products, reject immediately
        if (!this.config.roleConfig.radio_performance_analytics.isEnabled
            && !this.config.roleConfig.radio_proposal_opt.isEnabled
            && !this.config.roleConfig.radio_rate_card.isEnabled
        ) {
            return false;
        }

        switch (url[0]) {
            case 'admin': {
                let product: ProposalProProductConfig | RateOpticsProductConfig | PerformanceAnalyticsProductConfig;

                switch (url[1]) {
                    case 'ProposalPRO': {
                        product = this.config.roleConfig.radio_proposal_opt;
                        break;
                    }
                    case 'RateOptics': {
                        product = this.config.roleConfig.radio_rate_card;
                        break;
                    }
                    case 'PerformanceAnalytics': {
                        product = this.config.roleConfig.radio_performance_analytics;
                        break;
                    }
                    default: {
                        return false;
                    }
                }
                return this.canAccessAdmin(url.slice(1, url.length), product);
            }
            case 'RateOptics': {
                return this.canAccessRateOptics(url.slice(1, url.length), this.config.roleConfig.radio_rate_card);
            }
            case 'ProposalPRO': {
                return this.canAccessProposalPro(url.slice(1, url.length), this.config.roleConfig.radio_proposal_opt);
            }


        }
    }

    canActivateTv(url: string[]): boolean {
        // If the user doesn't have access to any tv products, reject immediately
        if (!this.config.roleConfig.tv_performance_analytics.isEnabled
            && !this.config.roleConfig.tv_proposal_opt.isEnabled
            && !this.config.roleConfig.tv_rate_card.isEnabled
        ) {
            return false;
        }


        switch (url[0]) {
            case 'admin': {
                let product: ProposalProProductConfig | RateOpticsProductConfig | PerformanceAnalyticsProductConfig;

                switch (url[1]) {
                    case 'ProposalPRO': {
                        product = this.config.roleConfig.tv_proposal_opt;
                        break;
                    }
                    case 'RateOptics': {
                        product = this.config.roleConfig.tv_rate_card;
                        break;
                    }
                    case 'PerformanceAnalytics': {
                        product = this.config.roleConfig.tv_performance_analytics;
                        break;
                    }
                    default: {
                        return false;
                    }
                }
                return this.canAccessAdmin(url.slice(1, url.length), product);
            }
            case 'RateOptics': {
                return this.canAccessRateOptics(url.slice(1, url.length), this.config.roleConfig.tv_rate_card);
            }
            case 'ProposalPRO': {
                return this.canAccessProposalPro(url.slice(1, url.length), this.config.roleConfig.tv_proposal_opt);
            }


        }
    }

    canAccessAdmin(
        url: string[],
        product: RateOpticsProductConfig | ProposalProProductConfig | PerformanceAnalyticsProductConfig
    ): boolean {
        if (product && product.admin.isEnabled && product.admin.admin_access) {
            return true;
        }
        return false;
    }

    canAccessRateOptics(url: string[], product: RateOpticsProductConfig) {
        switch (url[0]) {
            case 'DynamicRateCard': {
                return product.dynamic_rate_card.dynamic_rate_card_access;
            }
            case 'RateGenerator': {
                return product.rate_generator.rate_generator_access;
            }
            case 'PoliticalLUR': {
                return product.political_lur.political_lur_access;
            }
            default: {
                return false;
            }
        }
    }

    canAccessProposalPro(url: string[], product: ProposalProProductConfig) {
        switch (url[0]?.replace(/\?.*$/, '')) {
            case 'ProposalSummary': {
                return product.proposal_opt.proposal_opt_access;
            }
            case 'ProposalBuilder': {
                return product.proposal_opt.proposal_opt_access;
            }
            case 'LaunchPadPick': {
                if (this.config.featureFlag || this.config.userRoleId === PLANNING_MEMBER_USER_ROLE_ID || url[0].includes('sfdc_oppt_id')) {
                    return product.proposal_opt.proposal_opt_access;
                } else {
                    return false;
                }
            }
            case 'LaunchPadBuild': {
                if (this.config.featureFlag || this.config.userRoleId === PLANNING_MEMBER_USER_ROLE_ID || url[0].includes('sfdc_oppt_id')) {
                    return product.proposal_opt.proposal_opt_access;
                } else {
                    return false;
                }
            }
            case 'LaunchPadSearch': {
                if (this.config.featureFlag || this.config.userRoleId === PLANNING_MEMBER_USER_ROLE_ID || url[0].includes('sfdc_oppt_id')) {
                    return product.proposal_opt.proposal_opt_access;
                } else {
                    return false;
                }
            }
            case 'RateGuide': {
                return product.rate_guide.rate_guide_access;
            }
            case 'InventoryManagement': {
                return product.inventory_management.inventory_management_access;
            }
            case 'InventoryReport': {
                return product.inventory_reports.inventory_reports_access;
            }
            case 'PostBuyAnalysis': {
                return product.post_buy_analysis?.post_buy_analysis_access;
            }
            default: {
                return false;
            }
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UrlStore } from '@shared/helpers/url-store';

@Component({
    selector: 'app-access-denied',
    templateUrl: './access-denied.component.html',
    styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

    constructor(private router: Router) { }

    ngOnInit() {
    }

    goHome() {
        this.router.navigate([UrlStore.ui.landing]);
    }

}

import {
    ProcessCellForExportParams,
    ProcessHeaderForExportParams,
    GridApi,
    ColumnApi,
    ExcelCell,
    ExcelDataType,
    ColDef
} from 'ag-grid-community';
import {currencyFormatter, getDisplayText} from './helpers';
import {TOTAL_ROW_ID, TOTAL_WEEKLY_ROW_ID} from './ag-grid-builder';

const EXCEL_MAX_SHEET_NAME_LENGTH = 31;
const MIN_COL_WIDTH = 100;

// This function is not covered by tests
export function exportToExcel(
    fileName: string,
    gridApi: GridApi,
    columnApi: ColumnApi,
    excludedColumns: string[] = [],
    hasTotalRow: boolean = false,
    footerData?: { [key: string]: { data?: any, direction?: 'down' | 'across', bold?: boolean } },
    headerData?: { [key: string]: { data?: any, direction?: 'down' | 'across', bold?: boolean } }[],
    wrappingHeader?: string,
    agGridToExcelMagicConversion = 1.68,
    showHiddenColumns: string[] = [],
) {
    const exportParams: any = {};
    const allIncludedColumns = columnApi.getAllColumns()
        .filter(col => {
            const parent = col.getParent()?.isExpanded();
            const colGroupShow = col.getColDef().columnGroupShow;
            return parent === null
                || !colGroupShow
                || colGroupShow === 'always'
                || (parent && colGroupShow === 'open')
                || (!parent && colGroupShow === 'closed');
        })
        .filter(col => (!col.getColDef().hide || showHiddenColumns.includes(col.getColDef().field) || col.isRowGroupActive())
                && !excludedColumns.includes(col.getColDef().field))
        .map(col => col.getColId());
    exportParams.columnKeys = allIncludedColumns;

    const firstVisibleColumn = columnApi.getAllColumns()
        .find(col => !col.getColDef().hide && !excludedColumns.includes(col.getColDef().field));

    exportParams.skipGroups = true;
    exportParams.processCellCallback = (params: ProcessCellForExportParams) => {
        if (!params.node.data) {
            return;
        }
        const dataValue = params.node.data[params.column.getColDef().field];
        if (dataValue && dataValue.current) {
            return currencyFormatter(dataValue.current, '1.2-2');
        } else if (hasTotalRow && params.node.data.id === TOTAL_ROW_ID && params.column === firstVisibleColumn) {
            return 'Total:';
        } else if (hasTotalRow && params.node.data.id === TOTAL_WEEKLY_ROW_ID && params.column === firstVisibleColumn) {
            return 'Weekly Spend Total:';
        }
        let isPercent = false;
        if (params.node.data.format?.type === 'percent' || params.column.getColDef().headerComponentParams.formatType === 'percent') {
            isPercent = true;
        }
        return params.value && params.value.hasOwnProperty('name')
            ? params.value.name
            : isPercent && Number(params.value)
                ? params.value / 100
                : params.value;
    };
    exportParams.processHeaderCallback = (params: ProcessHeaderForExportParams) => {
        return params.column.getColDef()?.headerComponentParams.name;
    };

    if (footerData) {
        exportParams.customFooter = generateCustomCells(
            footerData,
            exportParams.columnKeys.length,
            'white-background',
            true
        ).filter(row => row);
        exportParams.customFooter.unshift([{data: {value: '', type: 'String' as ExcelDataType}}, {
            data: {
                value: '',
                type: 'String' as ExcelDataType
            }
        }]);
    }

    exportParams.customHeader = [];
    if (headerData) {
        const renderedHeaderData: ExcelCell[][] = [];
        let startingIndex = 0;
        headerData.forEach((block, index) => {
            generateCustomCells(
                block,
                exportParams.columnKeys.length - startingIndex,
                'gray-background',
                index === headerData.length - 1
            ).filter(row => row).forEach((row, rowNum) => {
                if (renderedHeaderData[rowNum]) {
                    renderedHeaderData[rowNum].push(...row);
                } else {
                    renderedHeaderData[rowNum] = fillTableWithColor([], startingIndex, 'gray-background');
                    renderedHeaderData[rowNum].push(...row);
                }
            });
            startingIndex = Math.max(startingIndex, ...renderedHeaderData.map(row => row.length));
        });
        renderedHeaderData.forEach(row => {
            fillTableWithColor(row, exportParams.columnKeys.length, 'gray-background');
        });
        exportParams.customHeader = renderedHeaderData;
    }
    if (wrappingHeader) {
        exportParams.customHeader.unshift([
            {
                data: {value: wrappingHeader, type: 'String'},
                styleId: 'header',
                mergeAcross: exportParams.columnKeys.length - 1
            }]);
    }

    exportParams.columnGroups = true;
    exportParams.columnWidth = (params) => {
        if (params.column?.getColDef().cellRendererFramework && params.column.getActualWidth() > 200) {
            return Math.max(params.column?.getActualWidth() / 2 || MIN_COL_WIDTH * 2);
        }
        return Math.max(params.column?.getActualWidth() / agGridToExcelMagicConversion, MIN_COL_WIDTH);
    };
    exportParams.fileName = fileName.replace(/[^A-Za-z0-9 -]/g, ' ').trim();
    exportParams.sheetName = exportParams.fileName.substr(0, EXCEL_MAX_SHEET_NAME_LENGTH);
    gridApi.exportDataAsExcel(exportParams);
}

function generateCustomCells(
    data: { [key: string]: { data?: any, direction?: 'down' | 'across', bold?: boolean } },
    remainingLineLength: number,
    color: string,
    isLast: boolean = false,
): ExcelCell[][] {
    const customCells: ExcelCell[][] = [];
    Object.keys(data)
        .forEach(key => {
            if (data[key].data instanceof Array) {
                if (data[key].data.length > 0) {
                    if (data[key].direction === 'across') {
                        const row: ExcelCell[] = [];
                        row.push({data: {value: key, type: 'String' as ExcelDataType}, styleId: color + '-key-across'});
                        row.push(...data[key].data.map(line => (
                            {
                                data: {value: getDisplayText(line), type: line ? 'String' : 'Boolean'},
                                styleId: data[key].bold ? color + '-key' : color
                            }
                        )));
                        if (isLast) {
                            fillTableWithColor(row, remainingLineLength, color);
                        } else {
                            fillTableWithColor(row, row.length + 1, color);
                        }
                        customCells.push(row);


                    } else {
                        const row: ExcelCell[] = [];
                        row.push({data: {value: key, type: 'String' as ExcelDataType}, styleId: color + '-key'});
                        row.push({
                            data: {value: getDisplayText(data[key].data[0]), type: 'String' as ExcelDataType},
                            styleId: data[key].bold ? color + '-key' : color
                        });
                        if (isLast) {
                            fillTableWithColor(row, remainingLineLength, color);
                        } else {
                            fillTableWithColor(row, row.length + 1, color);
                        }

                        customCells.push(row);

                        (data[key].data as Array<string>).slice(1).forEach(line => {
                            const nextRow: ExcelCell[] = [];
                            nextRow.push({data: {value: '', type: 'String' as ExcelDataType}, styleId: color + '-key'});
                            nextRow.push({
                                data: {value: getDisplayText(line), type: 'String' as ExcelDataType},
                                styleId: data[key].bold ? color + '-key' : color
                            });
                            if (isLast) {
                                fillTableWithColor(nextRow, remainingLineLength, color);
                            } else {
                                fillTableWithColor(nextRow, row.length + 1, color);
                            }

                            customCells.push(nextRow);
                        });
                    }

                }
            } else if (getDisplayText(data[key].data) || getDisplayText(data[key].data) === '') {
                const displayText = getDisplayText(data[key].data);
                const row: ExcelCell[] = [];
                const styleId = displayText ?
                    data[key].direction === 'across'
                        ? color + '-key-across'
                        : color + '-key'
                    : color;
                row.push({data: {value: key, type: 'String' as ExcelDataType}, styleId});
                if (displayText.trim()) {
                    row.push({
                            data: {
                                value: displayText,
                                type: 'String' as ExcelDataType
                            },
                            styleId: data[key].bold ? color + '-key' : color
                        }
                    );
                }
                if (isLast) {
                    fillTableWithColor(row, remainingLineLength, color);
                } else {
                    fillTableWithColor(row, row.length + 1, color);
                }

                customCells.push(row);
            }
        });
    return customCells;
}

function fillTableWithColor(row: ExcelCell[], lineLength: number, color: string): ExcelCell[] {
    if (row.length !== lineLength) {
        row.push(
            {
                data: {
                    value: null,
                    type: 'Boolean'
                },
                styleId: color
            }
        );
        return fillTableWithColor(
            row,
            lineLength,
            color
        );
    }
    return row;
}

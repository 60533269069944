<app-dialog-container [loading]="false" (closeEvent)="onClose()">
    <div class="is-flex align-center justify-center break-newline has-text-centered">{{data.text}}</div>
    <br />
    <div class="level is-full-width">
          <div class="level-item">
              <a class="button level-item is-primary" (click)="onConfirm()">{{data.confirmText}}</a>
          </div>
          <div class="level-item">
              <a class="button level-item is-danger" (click)="onDeny()">{{data.denyText}}</a>
          </div>
    </div>
</app-dialog-container>

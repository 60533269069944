import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {CellClassParams, CellClickedEvent, ColDef, GetDataPath, ICellRendererFunc, ICellRendererParams} from 'ag-grid-community';
import {ProposalSummary} from '@models/proposal-summary/proposal-summary';
import {AgGridApiDirective} from '@shared/helpers/ag-grid';
import {deadlineDateClass, expirationDateClass, LOCALE_ID} from '@shared/helpers/helpers';
import {UrlStore} from '@shared/helpers/url-store';
import {Router} from '@angular/router';
import {exportToExcel} from '@shared/helpers/excel-export';
import {GridOptions} from 'ag-grid-community';
import {cellClass, cellClassRules, excelStyles} from '@shared/helpers/ag-grid-builder';
import {ProposalSummaryFilters} from '@models/proposal-summary/proposal-summary-filters';
import {generateToPdfFromAgGridApi} from '@shared/helpers/pdf-export';
import {LookupService} from '@services/lookup/lookup.service';
import * as moment from 'moment';
import {ProposalOptService} from '@services/proposal-opt/proposal-opt.service';
import {NotifyDialogComponent} from '@shared/components/notify-dialog/notify-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {StatusService} from '@services/status/status.service';
import {SiteDownWarning} from '@models/status';
import {formatDate} from '@angular/common';
import {cloneDeep} from 'lodash';
import {UiConfig} from '@models/config';
import {AuthService} from '@services/auth/auth.service';
import { Grid } from '@models/lookup';
import { ConfirmDialogComponent, CONFIRM_DIALOG } from '@shared/components/confirm-dialog/confirm-dialog.component';

const EXCEL_DATE_FORMAT = 'M/D/YY';
export const ACCOUNT_EXECUTIVE_USER_ROLE_ID = '1';
export const PLANNING_MEMBER_USER_ROLE_ID = '2';
export const SALES_COORDINATOR_USER_ROLE_ID = '5';
export const PROPOSALPRO_ADMIN_USER_ROLE_ID = '6';
export const ACCOUNT_EXECUTIVE_WI_USER_ROLE_ID = '7';


@Component({
    selector: 'app-summary-grid',
    templateUrl: './summary-grid.component.html',
    styleUrls: ['./summary-grid.component.scss']
})
export class SummaryGridComponent extends AgGridApiDirective implements OnChanges, OnInit{
    @Input() columnDefs: ColDef[] = [];
    @Input() defaultColDef = {};
    @Input() isLoading = false;
    @Input() rowData: ProposalSummary[] = [];
    @Input() filters: ProposalSummaryFilters;
    @Input() summaryGrid: Grid<ProposalSummary>;

    isExportingToExcel = false;
    isExportingToPdf = false;

    gridOptions: GridOptions = {
        excelStyles
    };

    config: UiConfig;
    planningMemberUserRoleId = PLANNING_MEMBER_USER_ROLE_ID;
    isExpanded = false;
    public groupDefaultExpanded = 0;

    constructor(
        private router: Router,
        private lookupSvc: LookupService,
        private dialog: MatDialog,
        public proposalOptSvc: ProposalOptService,
        private authSvc: AuthService,
        public statusSrv: StatusService
    ) {
        super();
    }

    public autoGroupColumnDef: ColDef = {
        headerName: 'Group',
        minWidth: 0,
        maxWidth: 0,
        pinned: true,
        cellRendererParams: {
            suppressCount: true,
        },
    };

    public getDataPath: GetDataPath = (data: any) => {
        return data.proposalHierarchy;
    }

    expandAllProposalsScenarios() {
        if (this.isExpanded) {
            this.gridOptions.api.expandAll();
        }
        else {
            this.gridOptions.api.collapseAll();
        }
    }

    goToLaunch() {
        this.router.navigate([UrlStore.ui.radio.proposalProLaunchPick]);
    }

    actionButtonRenderer(params) {
        if (params.data.scenarioCount != null) {
            const rootElement = document.createElement<any>('span');
            if (this.config.featureFlag || this.config.userRoleId === PLANNING_MEMBER_USER_ROLE_ID) {
                rootElement.innerHTML = `<a class="button is-small is-primary is-clone"> Clone </a>
                    <a class="button is-small is-primary is-view"> View </a>`;
                const cloneAction = rootElement.querySelectorAll('.is-clone')[0];
                cloneAction.addEventListener('click', () => this.cloneHistory(params));
            } else {
                rootElement.innerHTML = `<a class="button is-small is-primary is-view"> View </a>`;
            }
            const viewAction = rootElement.querySelectorAll('.is-view')[0];
            viewAction.addEventListener('click', () => this.goToBuilder(params.data.proposalId));
            return rootElement;
        }
    }

    cloneHistory(params: CellClickedEvent) {
        const cloneUrl = this.router.serializeUrl(this.router.createUrlTree(
            [UrlStore.ui.radio.proposalProLaunchBuild],
            {queryParams: {proposalId: params.data.proposalId}}));
        window.open(cloneUrl, '_blank');
    }

    goToBuilder(proposalId: number) {
        const builderUrl = this.router.serializeUrl(this.router.createUrlTree(
            [UrlStore.ui.radio.proposalProOptimize.concat(`/${proposalId}`)],
            {queryParams: {tab: 'builder'}}));
        window.open(builderUrl, '_blank');
    }

    populateFlightRange() {

        this.gridOptions.rowData = this.gridOptions.rowData.map(
            row => {
                row.flightRange =
                    `${moment(row.flightStart).format(EXCEL_DATE_FORMAT)}-${moment(row.flightEnd).format(EXCEL_DATE_FORMAT)}`;
                row.createdDate = row.createdDate ?
                    moment(row.createdDate).format(EXCEL_DATE_FORMAT) : row.createdDate;
                row.clientDeadlineDate = row.clientDeadlineDate ?
                    moment(row.clientDeadlineDate).format(EXCEL_DATE_FORMAT) : row.clientDeadlineDate;
                return row;
            });
    }

    exportDialog(){
        const nonPrimaryScenariosDialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                text: 'Do you want to export non-primary scenarios?',
                autoConfirm: false,
                noClickClose: false,
                confirmText: 'Yes, include',
                denyText: 'No, exclude'
            }
        });
        return nonPrimaryScenariosDialog;
    }

    exportToExcel() {
        const nonPrimaryScenariosDialog = this.exportDialog();
        nonPrimaryScenariosDialog?.afterClosed().subscribe(nonPrimaryScenariosSelection => {
            const nonPrimaryScenarios = (nonPrimaryScenariosSelection === CONFIRM_DIALOG);
            if (!this.isExportingToExcel) {
                this.isExportingToExcel = true;

                this.populateFlightRange();
                const gridApi = cloneDeep(this.gridApi);
                const rowData = cloneDeep(this.rowData);
                if (!nonPrimaryScenarios){
                    gridApi.setRowData(rowData.filter(data => data.isPrimary));
                }

                exportToExcel(
                    'Proposal Summary Export',
                    gridApi,
                    this.gridColumnApi,
                    ['actions', 'bulkSelect'],
                    false,
                    undefined,
                    undefined,
                    'Proposal Summary');
                this.isExportingToExcel = false;
            }
        });
    }

    exportToPdf() {
        const nonPrimaryScenariosDialog = this.exportDialog();
        nonPrimaryScenariosDialog?.afterClosed().subscribe(nonPrimaryScenariosSelection => {
            const nonPrimaryScenarios = (nonPrimaryScenariosSelection === CONFIRM_DIALOG);
            if (!this.isExportingToPdf) {
                this.isExportingToPdf = true;

                this.populateFlightRange();

                let rowData = cloneDeep(this.rowData);
                if (!nonPrimaryScenarios){
                    rowData = rowData.filter(data => data.isPrimary);
                }

                const gridApi = cloneDeep(this.gridApi);
                const gridColumnApi = cloneDeep(this.gridColumnApi);

                generateToPdfFromAgGridApi(
                    'Proposal Summary Export',
                    rowData,
                    gridApi,
                    gridColumnApi,
                    ['actions', 'bulkSelect'],
                    false,
                    false,
                    'Proposal Summary',
                    true,
                    {
                        leftSideImageLookup: this.lookupSvc.getClientLogo(),
                    },
                );
                this.isExportingToPdf = false;
            }
        });
    }

    ngOnChanges() {
        if (this.isLoading) {
            this.isExpanded = false;
        }
        this.columnDefs?.unshift(
            {
                headerName: 'Actions',
                field: 'actions',
                cellRenderer: (params: any) => this.actionButtonRenderer(params),
                minWidth: 100,
                cellClass: (params: CellClassParams) => cellClass(params, undefined),
                cellClassRules: cellClassRules(),
                pinned: true,
            }
        );
        this.columnDefs?.forEach(colDef => {
            if (colDef.field === 'scenarioCount') {
                const scenarioCellRenderer: ICellRendererFunc = colDef.cellRenderer as ICellRendererFunc;
                colDef.cellRenderer = (params: ICellRendererParams) => {
                    if (scenarioCellRenderer(params) != null) {
                        const scenarioElement: HTMLElement = document.createElement<any>('div');
                        scenarioElement.className = 'scenarios-cell-wrapper';

                        if (params.data.scenarioCount >= 2 && !params.node.expanded) {
                            scenarioElement.innerHTML = `<a class="is-select fas fa-fw fa-angle-right"></a>
                            <a style="margin-right: 15px;" class="is-select"> ${scenarioCellRenderer(params)} </a>`;
                        }
                        else if (params.data.scenarioCount >= 2 && params.node.expanded) {
                            scenarioElement.innerHTML = `<a class="is-select fas fa-fw fa-angle-down"></a>
                            <a style="margin-right: 15px;" class="is-select"> ${scenarioCellRenderer(params)} </a>`;
                        }
                        else {
                            scenarioElement.innerHTML = `<a class="scenarios is-select"> ${scenarioCellRenderer(params)} </a>`;
                        }
                        const viewAction = scenarioElement.querySelectorAll('.is-select');
                        let i: number;
                        for (i = 0 ; i < viewAction.length; i++) {
                            viewAction[i].addEventListener('click', () => this.expandProposalScenarios(params));
                        }
                        return scenarioElement;
                    }
                };
            }
        });
        this.columnDefs?.forEach(colDef => {
            if (colDef.field === 'proposalId' || colDef.field === 'advertiserName' || colDef.field === 'agencyName' ||
            colDef.field === 'proposalName' || colDef.field === 'flightRange' || colDef.field === 'status' ||
            colDef.field === 'clientDeadlineDate' || colDef.field === 'expirationDate' || colDef.field === 'actions'){
                colDef.cellClass = (params: ICellRendererParams) => {
                    const classes = cellClass(params, this.summaryGrid?.columns?.find(col => col.field === params.colDef.field).format);
                    this.gridOptions.api.forEachNode(node => {
                        if (node.data.scenarioCount != null) {
                            if (params.colDef.field === 'proposalId' || params.colDef.field === 'advertiserName' ||
                                params.colDef.field === 'agencyName' || params.colDef.field === 'proposalName') {
                                if (params.data.scenarioCount >= 2 && params.node.expanded) {
                                    classes.push('bold');
                                }
                            }
                        }
                    });
                    if (params.colDef.field === 'flightRange') {
                        classes.push(
                            params.data.status !== 'Complete'
                                && params.data.status !== 'Closed'
                                && moment(params.data.flightStart).isBefore(moment().startOf('day'))
                                ? 'invalid-cell'
                                : '');
                    }
                    if (params.colDef.field === 'status') {
                        classes.push(params.value.toLocaleLowerCase());
                    } else if (params.colDef.field === 'clientDeadlineDate') {
                        classes.push(deadlineDateClass(params.data.status, params.data.clientDeadlineDate));
                    } else if (params.colDef.field === 'expirationDate') {
                        classes.push(expirationDateClass(params.data.expirationDate));
                    }
                    return classes;
                };
            }
        });
    }

    expandProposalScenarios(params: ICellRendererParams) {
        params.node.setExpanded(!params.node.expanded);
        this.gridOptions.api.redrawRows();
    }

    ngOnInit() {
        this.config = this.authSvc.getConfig();
        this.statusSrv.siteDownWarning().subscribe((warning: SiteDownWarning) => {
            this.dialog.open(NotifyDialogComponent, {
                data: {
                    text: `Warning: ProposalPro will be down from ` +
                        `${formatDate(moment.utc(warning?.startDatetime).local().toDate(), 'medium', LOCALE_ID)} to ` +
                        `${formatDate(moment.utc(warning?.endDatetime).local().toDate(), 'medium', LOCALE_ID)}. \n` +
                        `${warning?.message.replace('\\n', '\n')}`,
                    autoConfirm: warning == null,
                    noClickClose: false
                }
            });

        });
    }
}

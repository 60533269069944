import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UrlStore} from '@shared/helpers/url-store';
import {SystemDataStatus, SiteDownStatus, SiteHeartbeat, SiteDownWarning} from '@models/status';


@Injectable({
    providedIn: 'root'
})
export class StatusService {
    constructor(private http: HttpClient) {
    }

    getSystemDataStatus() {
        return this.http.get<SystemDataStatus>(UrlStore.api.status.systemDataStatus);
    }

    siteDownStatus() {
        return this.http.get<SiteDownStatus>(UrlStore.api.status.siteDownStatus);
    }

    siteDownWarning() {
        return this.http.get<SiteDownWarning>(UrlStore.api.status.siteDownWarning);
    }

    siteHeartbeat() {
        return this.http.get<SiteHeartbeat>(UrlStore.api.status.siteHeartbeat);
    }
}

import {
    GridApi,
    ColumnApi,
    ColDef,
    ColumnGroup,
    ColGroupDef,
} from 'ag-grid-community';
import {Observable} from 'rxjs';
import {getDisplayText} from './helpers';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import {ClientLogo, GridFormat} from '@models/lookup';
import {TOTAL_ROW_ID, TOTAL_WEEKLY_ROW_ID} from '@shared/helpers/ag-grid-builder';
import {cloneDeep} from 'lodash';

const LANDSCAPE_PDF_PAGE_WIDTH = 840;
const LANDSCAPE_PDF_PAGE_HEIGHT = 600;
const PDF_MARGIN = 20;
const PDF_HEADER_HEIGHT = 80;
const LENGTH_OF_WEEK_COLUMN = 6;
const UNIT_RATE_FORMAT: GridFormat = {type: 'currency', format: '1.0-2'};

// This function is not covered by tests
export function generateToPdfFromAgGridApi(
    fileName: string,
    data: any[],
    gridApi: GridApi,
    columnApi: ColumnApi,
    excludedColumns: string[] = [],
    hasTotalRow: boolean = false,
    hasGroupedColumns: boolean = false,
    headerWrapper?: string,
    isExport: boolean = false,
    customHeader?: {
        leftSideImageLookup?: Observable<ClientLogo>,
        reportTitle?: string,
        reportSubtitles?: string[],
        rightSideLines?: { boldText: string, italicText: string }[]
    },
    customFooter?: {
        footerLookup?: Observable<string>
    }
): jsPDF {
    const pdf = new jsPDF('l', 'pt');
    generatePdfFromAgGridApi(
        pdf,
        fileName,
        data,
        gridApi,
        columnApi,
        excludedColumns,
        hasTotalRow,
        hasGroupedColumns,
        headerWrapper,
        customHeader,
        customFooter
    );

    addPageCount(pdf);


    if (isExport) {
        pdf.save(fileName.split('.').join(' '));
    }
    return pdf;
}

export function generateToPdfFromTables(
    fileName: string,
    data: any[][],
    columns: ColDef[][] | ColGroupDef[][],
    excludedColumns: string[][] = [],
    hasTotalRow: boolean[] = [],
    headerWrapper?: string[],
    isExport: boolean = false,
    customHeader?: {
        leftSideImageLookup?: Observable<ClientLogo>,
        reportTitle?: string,
        reportSubtitles?: string[],
        rightSideLines?: { boldText: string, italicText: string }[]
    },
    customFooter?: {
        footerLookup?: Observable<string>
    },
    signatureBlock: boolean = false,
    pageBreaks?: string[]
): jsPDF {
    const pdf = new jsPDF('l', 'pt');
    generatePdfFromTables(
        pdf,
        fileName,
        data,
        columns,
        excludedColumns,
        hasTotalRow,
        headerWrapper,
        customHeader,
        customFooter,
        signatureBlock,
        pageBreaks
    );

    addPageCount(pdf);

    if (isExport) {
        pdf.save(fileName.split('.').join(' '));
    }
    return pdf;
}

export function generatePdfFromTables(
    pdf: any,
    fileName: string,
    data: any[][],
    columns: ColDef[][] | ColGroupDef[][],
    excludedColumns: string[][] = [],
    hasTotalRow: boolean[] = [],
    headerWrapper: string[] = [],
    customHeader?: {
        leftSideImageLookup?: Observable<ClientLogo>,
        reportTitle?: string,
        reportSubtitles?: string[],
        rightSideLines?: { boldText: string, italicText: string }[]
    },
    customFooter?: {
        footerLookup?: Observable<string>
    },
    signatureBlock: boolean = false,
    pageBreaks?: string[]
) {
    let tableStart = PDF_MARGIN;

    if (customHeader) {
        tableStart = generatePdfHeader(pdf, customHeader);
    }


    columns.forEach((tableColumns: ColDef[] | ColGroupDef[], index: number) => {
        let formatColumns: ColDef[] = tableColumns;
        if ('children' in tableColumns[0]) {

            formatColumns = [];
            (tableColumns as ColGroupDef[]).forEach((colGroup: ColGroupDef) => {
                return formatColumns.push(...colGroup.children);
            });

        }
        generatePdfTableFromAgGridColumns(
            pdf,
            transformDataUsingColumns(data[index], formatColumns),
            tableColumns,
            excludedColumns[index],
            hasTotalRow[index],
            headerWrapper[index],
            index === 0 ? tableStart : undefined,
            pageBreaks[index]
        );
    });

    if (signatureBlock) {
        generatePdfTable(
            pdf,
            [{
                signatureData:
                    'x__________________________________________________________________________'
            }, {
                signatureData: 'Signature'
            }, {
                signatureData: '__________________________________________'
            }, {
                signatureData: 'Date'
            }],
            [
                {title: '', dataKey: 'signatureData'}
            ],
            undefined,
            undefined,
            {footerText: {halign: 'left'}},
            undefined,
            undefined,
            {
                theme: 'grid',
                pageBreak: 'avoid',
                tableWidth: 'auto',
                styles: {
                    cellWidth: 'auto',
                    fontSize: 5,
                    halign: 'left',
                    cellPadding: {
                        top: 5,
                        bottom: 5,
                        left: 3,
                        right: 3,
                    },
                    textColor: [0, 0, 0],
                    lineWidth: 0.01
                },
                headStyles: {
                    fillColor: [0, 0, 0],
                    textColor: [255, 255, 255],
                    halign: 'center'
                },
                showHead: 'never',
                border: 0,
                alternateRowStyles: {fillColor: [255, 255, 255]},
                bodyStyles: {
                    lineColor: [0, 0, 0, 0]
                },
                margin: {left: 20, right: 20},
                columnStyles: {footerText: {halign: 'left'}},
                drawHeaderRow() {
                    return false;
                }
            });
    }

    if (customFooter) {
        customFooter.footerLookup.subscribe(footerText => {
            generatePdfTable(
                pdf,
                [{footerText}],
                [
                    {title: 'Terms & Conditions', dataKey: 'footerText'}],
                undefined,
                undefined,
                {footerText: {halign: 'left'}});
        });
    }
}

export function generatePdfFromAgGridApi(
    pdf: any,
    fileName: string,
    data: any[],
    gridApi: GridApi,
    columnApi: ColumnApi,
    excludedColumns: string[] = [],
    hasTotalRow: boolean = false,
    hasGroupedColumns: boolean = false,
    headerWrapper?: string,
    customHeader?: {
        leftSideImageLookup?: Observable<ClientLogo>,
        reportTitle?: string,
        reportSubtitles?: string[],
        rightSideLines?: { boldText: string, italicText: string }[]
    },
    customFooter?: {
        footerLookup?: Observable<string>
    }
) {
    let tableStart = PDF_MARGIN;

    if (customHeader) {
        tableStart = generatePdfHeader(pdf, customHeader);
    }

    generatePdfTableFromAgGridApi(
        pdf,
        transformDataUsingColumnApi(data, columnApi),
        columnApi,
        excludedColumns,
        hasTotalRow,
        hasGroupedColumns,
        headerWrapper,
        tableStart
    );

    if (customFooter) {
        customFooter.footerLookup.subscribe(footerText => {
            generatePdfTable(
                pdf,
                [{footerText}],
                [{title: 'Terms & Conditions', dataKey: 'footerText'}],
                undefined,
                undefined,
                {footerText: {halign: 'left'}});
        });
    }
}

export function generatePdfHeader(
    pdf: any,
    customHeader?: {
        leftSideImageLookup?: Observable<ClientLogo>,
        reportTitle?: string,
        reportSubtitles?: string[],
        rightSideLines?: { boldText: string, italicText: string }[]
    }
): number {
    pdf.setDrawColor(0, 0, 0);
    pdf.setFillColor(220, 220, 220);
    pdf.rect(PDF_MARGIN, PDF_MARGIN, LANDSCAPE_PDF_PAGE_WIDTH - PDF_MARGIN * 2, PDF_HEADER_HEIGHT, 'FD');
    customHeader.leftSideImageLookup.subscribe(logo => {
        let pdfLogoHeight = 60;
        let pdfLogoWidth = logo.width / logo.height * pdfLogoHeight;
        while (pdfLogoWidth > 150) {
            pdfLogoHeight = pdfLogoHeight - 1;
            pdfLogoWidth = logo.width / logo.height * pdfLogoHeight;
        }
        pdf.addImage(logo.image, 'png', 30, PDF_MARGIN + (PDF_HEADER_HEIGHT - pdfLogoHeight)
                / 2, pdfLogoWidth, pdfLogoHeight, '', 'FAST');
    });

    if (customHeader.reportTitle) {
        pdf.text(customHeader.reportTitle, 420, 40, 'center');
    }
    pdf.setFontSize(12);
    let subTitleStart = 60;
    if (customHeader.reportSubtitles) {
        customHeader.reportSubtitles.forEach(subTitle => {
            if (subTitle) {
                pdf.text(subTitle, 420, subTitleStart, 'center');
                subTitleStart = subTitleStart + 15;
            }
        });
    }

    let sideBarStartHeight = PDF_MARGIN + 10;
    let sideBarStartWidth = 0;

    pdf.setFontSize(8);
    pdf.setFontStyle('bold');

    if (customHeader.rightSideLines) {
        customHeader.rightSideLines.forEach(line => {
                if (line.italicText) {
                    sideBarStartWidth =
                        Math.max(
                            sideBarStartWidth,
                            pdf.getStringUnitWidth(line.boldText.concat(': ', line.italicText.toString())
                            )
                        );
                }
            }
        );

        sideBarStartWidth =
            LANDSCAPE_PDF_PAGE_WIDTH
            - 5
            - PDF_MARGIN
            - sideBarStartWidth * pdf.internal.getFontSize() / pdf.internal.scaleFactor;

        customHeader.rightSideLines.forEach(line => {
            if (line.italicText) {
                pdf.setFontStyle('bold');
                pdf.text(
                    line.boldText.concat(': '),
                    sideBarStartWidth,
                    sideBarStartHeight
                );
                const fieldTextSideBarWidth = sideBarStartWidth +
                    pdf.getStringUnitWidth(line.boldText.concat(': ')) *
                    pdf.internal.getFontSize() /
                    pdf.internal.scaleFactor;

                pdf.setFontStyle('italic');
                pdf.text(
                    line.italicText.toString(),
                    fieldTextSideBarWidth,
                    sideBarStartHeight
                );
                sideBarStartHeight += 10;
            }
        });
    }

    return PDF_MARGIN + PDF_HEADER_HEIGHT + PDF_MARGIN;
}

export function generatePdfTableFromAgGridApi(
    pdf: any,
    data: any[],
    columnApi: ColumnApi,
    excludedColumns: string[],
    hasTotalRow: boolean,
    hasGroupedColumns: boolean,
    headerWrapper?: string,
    tableStart?: number
) {
    const columnStyles = {};

    const includedColumns = columnApi.getAllColumns()
        .map(col => {
            const colDef = col.getColDef();
            if (
                !colDef.cellRenderer
                || !colDef.headerComponentParams?.formatType
                || colDef.headerComponentParams?.formatType.type === 'string'
            ) {
                columnStyles[colDef.field] = {halign: 'left'};
            }
            return colDef;
        })
        .filter(col => !col.hide && !excludedColumns.includes(col.field))
        .map(col => ({title: col.headerComponentParams.name, dataKey: col.field}));

    let groupedColumns;
    if (hasGroupedColumns) {
        groupedColumns = columnApi.getAllDisplayedColumnGroups().map((colGroup: ColumnGroup) =>
            ({
                name: colGroup.getColGroupDef().headerName,
                span: colGroup.getColGroupDef().children.filter((col: ColDef) =>
                    !col.hide && !excludedColumns.includes(col.field)
                ).length
            }));
    }

    if (hasTotalRow) {
        const totalRow = data.find(row => row.id === TOTAL_ROW_ID);
        const totalRowTwo = data.find(row => row.id === TOTAL_WEEKLY_ROW_ID);
        data = data.filter( row => row.id !== TOTAL_ROW_ID && row.id !== TOTAL_WEEKLY_ROW_ID);
        if (totalRow) {
            totalRow[includedColumns[0].dataKey] = 'Total:';
            data = data.concat(totalRow);
        }
        if (totalRowTwo) {
            totalRowTwo[includedColumns[0].dataKey] = 'Weekly Spend Total:';
            data = data.concat(totalRowTwo);
        }
    }

    generatePdfTable(pdf, data, includedColumns, groupedColumns, headerWrapper, columnStyles, tableStart, 'auto');

}

export function generatePdfTableFromAgGridColumns(
    pdf: any,
    data: any[],
    columns: ColGroupDef[] | ColDef[],
    excludedColumns: string[],
    hasTotalRow: boolean,
    headerWrapper?: string,
    tableStart?: number,
    pageBreak?: string
) {
    const columnStyles = {};
    let childrenColumns: ColDef[] = columns as ColDef[];
    let includedColumns = [];
    let groupedColumns;
    if ('children' in columns[0]) {
        groupedColumns = (columns as ColGroupDef[]).map((colGroup: ColGroupDef) =>
            ({
                name: colGroup.headerName,
                span: colGroup.children.filter((col: ColDef) =>
                    !col.hide && !excludedColumns.includes(col.field)
                ).length
            })
        ).filter((groupCol) => groupCol.span > 0);

        childrenColumns = [];
        (columns as ColGroupDef[]).forEach((colGroup: ColGroupDef) => {
            return childrenColumns.push(...colGroup.children);
        });

    }
    includedColumns = (childrenColumns as ColDef[])
        .map(col => {
            columnStyles[col.field] = {};
            if (
                !col.cellRenderer
                || !col.headerComponentParams?.formatType
                || col.headerComponentParams?.formatType.type === 'string'
            ) {
                columnStyles[col.field].halign = 'left';
            }
            columnStyles[col.field].cellWidth = col.width;
            return col;
        })
        .filter(col => !col.hide && !excludedColumns.includes(col.field))
        .map(col => ({
            title: col.hasOwnProperty('headerComponentParams') ? col.headerComponentParams.name : col.headerName,
            dataKey: col.field,
        }));

    if (hasTotalRow) {
        const totalRow = data.find(row => row.id === TOTAL_ROW_ID);
        const totalRowTwo = data.find(row => row.id === TOTAL_WEEKLY_ROW_ID);
        data = data.filter( row => row.id !== TOTAL_ROW_ID && row.id !== TOTAL_WEEKLY_ROW_ID);
        if (totalRow) {
            totalRow[includedColumns[0].dataKey] = 'Total:';
            data = data.concat(totalRow);
        }
        if (totalRowTwo) {
            totalRowTwo[includedColumns[0].dataKey] = 'Weekly Spend Total:';
            data = data.concat(totalRowTwo);
        }
    }

    generatePdfTable(pdf, data, includedColumns, groupedColumns, headerWrapper, columnStyles, tableStart, pageBreak);

}

export function generatePdfTable(
    pdf: any,
    data: any[],
    includedColumns: any[],
    groupedColumns: { name: string, span: number }[],
    headerWrapper: string,
    columnStyles?: any,
    tableStart?: number,
    pageBreak?: string,
    tableOptions?: any
) {
    const singleTableRowMax = 27;

    const options = tableOptions != null ? tableOptions : {
        theme: 'grid',
        pageBreak: pageBreak ? pageBreak : 'avoid',
        tableWidth: 'auto',
        styles: {
            cellWidth: 'auto',
            fontSize: 5,
            halign: 'right',
            cellPadding: {
                top: 5,
                bottom: 5,
                left: 3,
                right: 3,
            },
            textColor: [0, 0, 0]
        },
        headStyles: {
            fillColor: [62, 100, 126],
            textColor: [255, 255, 255],
            halign: 'center'
        },
        alternateRowStyles: {fillColor: [255, 255, 255]},
        bodyStyles: {fillColor: [242, 242, 242]},
        startY: tableStart,
        margin: {left: 20, right: 20},
        columnStyles
    };

    const singleTableRowLimit = Math.ceil(includedColumns.length / Math.ceil(includedColumns.length / singleTableRowMax));

    const tableColumns: any[][] = [];

    const tableGroupColumns: { name: string, span: number }[][] = [];

    if (groupedColumns) {
        let tableColumnIndex = 0;
        let tableGroupColumnIndex = 0;
        for (let i = 0; i < Math.ceil(includedColumns.length / singleTableRowLimit); i++) {
            tableGroupColumns[i] = [];
            tableColumns[i] = [];
            while (tableColumns[i].length < singleTableRowLimit && tableGroupColumnIndex < groupedColumns.length) {
                tableGroupColumns[i].push(groupedColumns[tableGroupColumnIndex]);
                tableColumns[i].push(
                    ...includedColumns.slice(tableColumnIndex, tableColumnIndex + groupedColumns[tableGroupColumnIndex].span)
                );
                tableColumnIndex += groupedColumns[tableGroupColumnIndex].span;
                tableGroupColumnIndex += 1;
            }
        }
    } else {
        for (let i = 0; i < Math.ceil(includedColumns.length / singleTableRowLimit); i++) {
            tableColumns[i] = includedColumns.slice(i * singleTableRowLimit, (i + 1) * singleTableRowLimit);
        }
    }


    tableColumns.forEach((tableColumn: any[], index: number) => {
        const head = [];
        if (headerWrapper) {
            head.push([{content: headerWrapper, colSpan: tableColumn.length}]);
        }
        if (groupedColumns) {
            head.push(tableGroupColumns[index].map(col => ({content: col.name, colSpan: col.span})));
        }
        head.push(tableColumn);
        pdf.autoTable({
            body: data,
            columns: tableColumn,
            head,
            ...options
        });

        options.startY = undefined;
    });

}

// This function is not covered by tests
export function transformDataUsingColumnApi(data: any[], columnApi: ColumnApi): any[] {
    data.forEach(row => {
        getRowValues(row);
    });
    columnApi.getAllColumns().forEach(col => {
        const colDef = cloneDeep(col.getColDef());
        applyCellRenderer(colDef, data);
    });
    return data;
}

export function transformDataUsingColumns(data: any[], cols: ColDef[]): any[] {
    data.forEach(row => {
        getRowValues(row);
    });
    cols.forEach(col => {
        applyCellRenderer(col, data);
    });
    return data;
}

function getRowValues(row: any) {
    Object.keys(row).forEach(key => {
        let formatValue;
        if (row[key] !== null && row[key] !== undefined && row[key].hasOwnProperty('value')) {
            formatValue = row[key].value;
        } else {
            formatValue = row[key];
        }
        let result = formatValue
            ? formatValue.hasOwnProperty('name') || formatValue.hasOwnProperty('value')
                ? getDisplayText(formatValue)
                : formatValue.hasOwnProperty('current')
                    ? formatValue.current
                    : formatValue
            : formatValue;

        if (!isNaN(+key) && key.length === LENGTH_OF_WEEK_COLUMN) {
            if (formatValue.hasOwnProperty('value')) {
                result = formatValue.value.new;
            } else {
                result = formatValue;
            }
        }
        row[key] = result;
    });
}

function applyCellRenderer(colDef: ColDef, data: any[]) {
    if (!colDef.hide) {
        let func: any = colDef.cellRenderer;
        // TODO: remove this special if possible as part of PPRO-317
        if (colDef.field === 'daypart' || colDef.field === 'scenarioCount') {
            func = (cellRendererParams) => cellRendererParams.value;
        }

        data.forEach(row => {
            if (func && func !== 'agGroupCellRenderer') {
                const modifiedRow = {};
                Object.keys(row).forEach(key => {
                    modifiedRow[key] = row[key] && (row[key].current || row[key].current === 0)
                        ? row[key].current
                        : row[key] && (row[key].new || row[key].new === 0)
                            ? row[key].new
                            : row[key];
                });
                const cellRendererParams = {
                    data: modifiedRow,
                    colDef,
                    value: modifiedRow[colDef.field]
                };

                const formatValue = func(
                    cellRendererParams,
                    colDef.field === 'unitRate' ? UNIT_RATE_FORMAT : null);
                if (formatValue) {
                    row[colDef.field] = { content: formatValue, nonformatValue: row[colDef.field] };
                    if (row[colDef.field].content !== row[colDef.field].nonformatValue) {
                        if ((!isNaN(+colDef.field) && colDef.field.length === LENGTH_OF_WEEK_COLUMN)
                            || colDef.field === 'averageSpots') {
                            row[colDef.field].styles = { halign: 'center' };
                        } else {
                            row[colDef.field].styles = { halign: 'right' };
                        }
                    }
                } else {
                    row[colDef.field] = formatValue;
                }
            }
        });
    }
}

function addPageCount(pdf: jsPDF) {
    const pageCount = pdf.internal.getNumberOfPages();
    pdf.setFontSize(8);
    for (let i = 1; i <= pageCount; i++) {
        pdf.setPage(i);
        pdf.text(`${i} / ${pageCount}`, LANDSCAPE_PDF_PAGE_WIDTH - 2 * PDF_MARGIN, LANDSCAPE_PDF_PAGE_HEIGHT - PDF_MARGIN, 'center');
    }
}

import {Component, OnInit, Inject} from '@angular/core';
import {FilterWidgetComponent} from '@shared/components/filter-widget/filter-widget.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export const CONFIRM_DIALOG = 'confirm';
export const DENY_DIALOG = 'deny';
export const CLOSE_DIALOG = 'close';


@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<FilterWidgetComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            text: string,
            autoConfirm: boolean,
            noClickClose: boolean,
            confirmText: string,
            denyText: string,
            denyClose: boolean
        }
    ) {
        dialogRef.disableClose = !data.noClickClose;
    }

    ngOnInit(): void {
        if (this.data.autoConfirm) {
            this.onConfirm();
        }
    }

    onConfirm() {
        this.dialogRef.close(CONFIRM_DIALOG);
    }

    onDeny() {
        this.dialogRef.close(DENY_DIALOG);
    }

    onClose() {
        this.dialogRef.close(this.data.denyClose === true ? DENY_DIALOG : CLOSE_DIALOG);
    }
}

import {Component, OnInit, Inject} from '@angular/core';
import {FilterWidgetComponent} from '@shared/components/filter-widget/filter-widget.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

const DEFAULT_DISMISS_MESSAGE = 'Okay';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './notify-dialog.component.html',
    styleUrls: ['./notify-dialog.component.scss']
})
export class NotifyDialogComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<FilterWidgetComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            text: string,
            autoConfirm: boolean,
            noClickClose: boolean,
            dismissText?: string}
    ) {
        dialogRef.disableClose = !data.noClickClose;
        data.dismissText = data.dismissText == null ? DEFAULT_DISMISS_MESSAGE : data.dismissText;
    }

    ngOnInit(): void {
        if (this.data.autoConfirm) {
            this.dialogRef.close(false);  // False if auto closed
        }
    }

    onDismiss() {
        this.dialogRef.close(true);  // True if user closed
    }
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import * as auth0 from 'auth0-js';
import {Auth0UserProfile} from 'auth0-js';
import {BehaviorSubject, Observable, forkJoin} from 'rxjs';
import * as decode from 'jwt-decode';
import {environment} from 'src/environments/environment';
import {ConfigService} from '@services/config/config.service';
import {UiConfig} from '@models/config';
import {UrlStore} from '@shared/helpers/url-store';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    userProfile: any;

    private protocol = window.location.hostname === 'localhost' ? 'http' : 'https';
    private auth0 = new auth0.WebAuth({
        ...environment.auth,
        redirectUri: `${this.protocol}://${window.location.hostname}:${window.location.port}/login/complete`
    });
    private timer = null;

    ExpiresIn: BehaviorSubject<number> = new BehaviorSubject(null);

    constructor(private router: Router, private configSvc: ConfigService) {
        this.parseToken(this.getAccessToken());
        // ensure the timer runs even if the user has refreshed the page
        this.startTimer();
    }

    public async getProfile(): Promise<any> {
        if (this.userProfile) {
            return this.userProfile;
        }

        const accessToken: string = this.getAccessToken();
        if (!accessToken) {
            throw new Error('Access Token must exist to fetch profile');
        }

        const self = this;
        return new Promise<any>((res, rej) => {
            this.auth0.client.userInfo(accessToken, (err, profile) => {
                if (err) {
                    rej(err);
                } else {
                    self.userProfile = profile;
                    res(profile);
                }
            });
        });
    }

    public beginLogin(landing: string = UrlStore.ui.landing, queryParams = {}): void {
        this.auth0.authorize();

        // the landing page must be stored in local storage since we will be redirecting
        // to auth0 for login and variables in memory will be wiped
        localStorage.setItem('landing', landing);
        localStorage.setItem('landingParams', JSON.stringify(queryParams));
    }

    public async completeLogin() {
        this.auth0.parseHash((err, result) => {
            if (err) {
                console.error(err);
                this.router.navigate([UrlStore.ui.loginError]);
                return;
            }

            this.auth0.client.userInfo(result.accessToken, async (e, info) => {
                if (e) {
                    console.error(e);
                } else {
                    this.parseToken(result.accessToken);
                    this.storeAuth(result, info);
                    forkJoin([this.loadConfig(), this.configSvc.getZendeskToken()])
                        .subscribe(
                            ([config, token]) => {
                                this.storeConfig(config);
                                this.storeZendeskToken(token.zendeskJwt);
                                const authScript = document.createElement('script');
                                const fullStoryScript = document.createElement('script');
                                fullStoryScript.appendChild(document.createTextNode(
                                    // tslint:disable: max-line-length
                                    `FS.identify(btoa(localStorage.getItem('email')), {
                                        displayName: JSON.parse(localStorage.getItem('userConfig')) ? JSON.parse(localStorage.getItem('userConfig')).userName : 'Unknown User',
                                        email: localStorage.getItem('email'),
                                    });`
                                    // tslint:enable: max-line-length
                                ));
                                authScript.appendChild(document.createTextNode(
                                    'window.zESettings = {authenticate: { jwt: localStorage.getItem("zendeskJwt") }};'
                                ));

                                document.head.appendChild(fullStoryScript);
                                document.head.appendChild(authScript);

                                // if (config.isSxmUser !== true) {
                                //     const widgetScript = document.createElement('script');
                                //     widgetScript.setAttribute(
                                //         'src', 'https://static.zdassets.com/ekr/snippet.js?key=df3efeb9-8fa8-444e-b9c0-1c7aebee2189');
                                //     widgetScript.setAttribute('id', 'ze-snippet');
                                //     document.head.appendChild(widgetScript);
                                // }

                                const landing = localStorage.getItem('landing') || UrlStore.ui.landing;
                                this.router.navigate([landing], {queryParams: JSON.parse(localStorage.getItem('landingParams'))});
                            },
                            error => {
                                console.error(error);
                                this.router.navigate([UrlStore.ui.loginError]);
                            });
                }
            });
        });
    }

    public logout() {
        console.log('Logging out and clearing localStorage.');
        localStorage.clear();
        this.auth0.logout({
            returnTo: `${this.protocol}://${window.location.hostname}:${window.location.port}`
        });
    }

    public isLoggedIn(): boolean {
        const token = localStorage.getItem('id_token');
        const expires = +localStorage.getItem('expires_at');

        return token && expires > new Date().getTime();
    }

    public isConfigured(): boolean {
        return localStorage.getItem('userConfig') !== null;
    }

    public getAccessToken(): string {
        return localStorage.getItem('access_token');
    }

    public getIdToken(): string {
        return localStorage.getItem('id_token');
    }

    public getEmail(): string {
        return localStorage.getItem('email');
    }

    public getProfilePic(): string {
        return localStorage.getItem('picture');
    }

    public getExpiresIn(): number {
        const expires = +localStorage.getItem('expires_at');
        return Math.max((expires - new Date().getTime()) / 1000, 0);
    }

    public getConfig(): UiConfig {
        const config = JSON.parse(localStorage.getItem('userConfig'));
        if (config) {
            return config;
        }
        this.logout();
    }

    public loadConfig(): Observable<UiConfig> {
        return this.configSvc.getConfig();
    }

    public goToRoot() {
        this.router.navigate(['/']);
    }

    public storeConfig(config: UiConfig): void {
        localStorage.setItem('userConfig', JSON.stringify(config));
    }

    public storeZendeskToken(token: string): void {
        localStorage.setItem('zendeskJwt', token);
    }

    private startTimer() {
        // setup a timer to check for expiring tokens and log the user out accordingly
        this.clearTimer();
        let timeLeft = this.getExpiresIn();

        if (!timeLeft) {
            return;
        }

        this.timer = setInterval(() => {
            timeLeft = this.getExpiresIn();
            this.ExpiresIn.next(timeLeft);
            if (timeLeft <= 0) {
                this.clearTimer();
                this.logout();
            }
        }, 500);
    }

    private clearTimer() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    private storeAuth(authResult, info: Auth0UserProfile) {
        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);
        localStorage.setItem('email', info.email.toLowerCase());
        localStorage.setItem('picture', info.picture);
    }

    private parseToken(token: string) {
        if (!token) {
            return;
        }

        const parsed = decode(token);
    }
}

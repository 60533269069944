import {formatCurrency, formatNumber, formatPercent, getLocaleCurrencySymbol, formatDate} from '@angular/common';
import * as moment from 'moment';
import {Moment} from 'moment';
import {
    LookupItem,
    AgencyLookupItem,
    ConstraintMix,
    NumericLookupItem,
    Grid
} from '@models/lookup';
import {Observable, of, timer} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {first, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {cloneDeep} from 'lodash';
import {ColDef} from 'ag-grid-community';

export const LOCALE_ID = 'en-US';
const SUNDAY = 7;
const MONDAY = 1;

const TOTAL_ROW_ID = -1;
const TOTAL_WEEKLY_ROW_ID = -2;

const FIRST_QUARTER_BASE_MM_DD = '0101';
const SECOND_QUARTER_BASE_MM_DD = '0401';
const THIRD_QUARTER_BASE_MM_DD = '0701';
const FOURTH_QUARTER_BASE_MM_DD = '1001';
const NOT_QUITE_ONE_WEEK = 6;

export const WIDEORBIT_SOURCE_SYSTEM_ID = '100';
export const SALESFORCE_SOURCE_SYSTEM_NAME = 'salesforce';

const DEFAULT_MOMENT_WEEKDAY_START_COUNT = 1;
const MAX_WEEKS_ALLOWED_BEFORE_QUARTERLY_BREAKDOWN = 13;
const LINE_BREAK_HEADERS = ['unitRate', 'spotLength', 'netRate', 'grossRateOTB',
    'grossRateLYFinal', 'rateFloor', 'rateTarget', 'rateGuide',
    'totalAvail', 'pendingMinsSold', 'minsSold',
    'currentSelloutPctDefault', 'currentSelloutPctAll', 'forecastedSelloutPct', 'lyFinalSelloutPct'
];
const FIRST_ARRAY_INDEX = 0;
const MAXIMUM_RETURNS = 3;
const RETURN_STRING = '\n';
const SPACE_STRING = ' ';

export const DEFAULT_AGENCY_NAME = '(No Agency) Direct Buy';
export const DEFAULT_AGENCY_LOOKUP: AgencyLookupItem = {
    id: -1,
    name: DEFAULT_AGENCY_NAME,
    defaultCommission: 0.0
};

export const NEW_AGENCY_COMMISSION = 0.15;

export const URL_3RD_PARTY_SOFTWARE_ATTRIBUTION = 'https://proposalpro.io/3rdpartylicenses.txt';

export const SCREEN_NAME_NIELSEN_DAYPART_MAPPINGS = 'Nielsen Daypart Mappings';
export const SCREEN_NAME_INVENTORY_REPORT = 'Inventory Report';
export const SCREEN_NAME_INVENTORY_MANAGEMENT = 'Inventory Management';
export const SCREEN_NAME_POST_BUY_ANALYSIS = 'Post Buy Analysis';
export const SCREEN_NAME_PROPOSAL_SUMMARY = 'Proposal Summary';
export const SCREEN_NAME_PROPOSAL_BUILDER = 'Proposal Builder';
export const SCREEN_NAME_TEMPORARY_MERGE = 'Merge Proposals';
export const SCREEN_NAME_PROPOSAL_INPUT = 'Proposal Input';
export const SCREEN_NAME_CHANNEL_ATTRIBUTES = 'Channel Attributes';
export const SCREEN_NAME_RATE_GUIDE = 'Rate Guide';
export const SCREEN_NAME_LAUNCH_PAD_BUILD = 'Launch Pad Build';
export const SCREEN_NAME_LAUNCH_PAD_SEARCH = 'Launch Pad Search';
export const SCREEN_NAME_LAUNCH_PAD_PICK = 'Launch Pad Pick';
export const SCREEN_NAME_UNMAPPED_ADVERTISERS_AGENCIES = 'Unmapped Advertisers/Agencies';
export const SCREEN_NAME_WIDEORBIT_ADVERTISERS_AGENCIES = 'WideOrbit Advertisers & Agencies';
export const SCREEN_NAME_USER_MANAGEMENT = 'User Management';
export const SCREEN_NAME_APPROVER_PRICING_SETTINGS = 'Approver & Pricing Settings';
export const SCREEN_NAME_GLOBAL_CUSTOM_DAYPARTS = 'Global Custom Dayparts';
export const REG_EXP_ASCII_VALUES = /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~’‘]*/g;

export const HYBRID_PROPOSALS_AGENCY_TEXT = 'HYBRID MEDIA';
export const HYBRID_PROPOSALS_SALES_COORD_ID = [];
export const CASUAL_PRECISION_PROPOSALS_AGENCY_TEXT = 'CASUAL PRECISION';
export const CASUAL_PRECISION_PROPOSALS_SALES_COORD_ID = [105, 106, 107, 114, 53];

export const SALES_COORDINATORS_COLUMN_FIELD = 'salesCoordinators';

function isHybridAgency(agency: AgencyLookupItem) {
    return agency?.displayName?.toUpperCase().includes(HYBRID_PROPOSALS_AGENCY_TEXT);
}

function isCasualPrecisionAgency(agency: AgencyLookupItem) {
    return agency?.displayName?.toUpperCase().includes(CASUAL_PRECISION_PROPOSALS_AGENCY_TEXT);
}

export function addAutomaticSalesCoordinators(agency: AgencyLookupItem,
                                              selectedSalesCoordinators: Array<any>, allSalesCoordinator: Array<any>){
    // For hybrid, the function currently adds empty list - leaving the function as-is, in case we need to add users in the future.
    if (isHybridAgency(agency)){
        const selectedSalesCoordinatorIds = selectedSalesCoordinators.map(item => item.id);
        selectedSalesCoordinators = selectedSalesCoordinators.concat(
            allSalesCoordinator.filter((item) => HYBRID_PROPOSALS_SALES_COORD_ID.includes(item.id) &&
            !selectedSalesCoordinatorIds.includes(item.id)));
    }
    if (isCasualPrecisionAgency(agency)){
        const selectedSalesCoordinatorIds = selectedSalesCoordinators.map(item => item.id);
        selectedSalesCoordinators = selectedSalesCoordinators.concat(
            allSalesCoordinator.filter((item) => CASUAL_PRECISION_PROPOSALS_SALES_COORD_ID.includes(item.id) &&
            !selectedSalesCoordinatorIds.includes(item.id)));
    }
    return selectedSalesCoordinators;
}

export function divide(numerator: number, denominator: number) {
    if (numerator === 0 || denominator === 0) {
        return 0;
    }

    return numerator / denominator;
}

export function currencyFormatter(value, digits: string = '1.2-2'): string {
    return formatCurrency(value, LOCALE_ID, getLocaleCurrencySymbol(LOCALE_ID), undefined, digits);
}

export function percentFormatter(value, digits: string = '1.0-1'): string {
    return formatPercent(value, LOCALE_ID, digits);
}

export function numberFormatter(value, digits: string = '1.0-0'): string {
    return formatNumber(value, LOCALE_ID, digits);
}

export function dateFormatter(value: Date, format: string = 'MM/dd/yyyy'): string {
    return formatDate(value, format, LOCALE_ID);
}

export function getDisplayText(item: LookupItem | NumericLookupItem | string): string {
    if (item || item === '') {
        if (typeof item === 'string') {
            return item;
        }
        if ((item as LookupItem).hasOwnProperty('name')) {
            return (item as LookupItem).displayName
                ? (item as LookupItem).displayName
                : (item as LookupItem).name;
        } else if ((item as NumericLookupItem).hasOwnProperty('value')) {
            return (item as NumericLookupItem).displayValue
                ? (item as NumericLookupItem).displayValue
                : (item as NumericLookupItem).value.toString();
        }
    }
    return undefined;
}

export function flightRangeFormatter(startDate: Date, endDate: Date, format: string = 'MM/dd/yyyy'): string {
    return formatDate(startDate, format, LOCALE_ID) + ' - ' + formatDate(endDate, format, LOCALE_ID);
}

export function deadlineDateClass(status: string, deadlineDate: Date): string {
    if (!(['Closed', 'Complete'].includes(status))) {
        if (deadlineDate !== null) {
            if (moment().isAfter(deadlineDate, 'day')) {
                return 'expired';
            }
        }
    }
}

export function expirationDateClass(expirationDate: Date): string {
    if (expirationDate !== null) {
        if (moment().isAfter(expirationDate, 'day')) {
            return 'expired';
        } else if (moment().add(2, 'days').isAfter(expirationDate, 'day')) {
            return 'expires-soon';
        }
    }
}

export function computeFlightWeeks(start: Moment, end: Moment): ConstraintMix[] {
    const startDate = moment(start).day(start.day() >= MONDAY ? MONDAY : MONDAY - 7);
    const lastDay = moment(end).add(SUNDAY - end.isoWeekday(), 'days');
    const items: LookupItem[] = [];

    while (startDate < lastDay) {
        items.push({name: dateFormatter(startDate.toDate(), 'MM/dd/yyyy')});
        startDate.add(1, 'week');
    }
    return items;
}

export function validateFlightWeeks(flightWeeks: ConstraintMix[], options: ConstraintMix[]): boolean {
    if (options.length > 0 && flightWeeks.length > 0 && flightWeeks.length !== options.length){
        if (flightWeeks[0].name === options[0].name && flightWeeks[flightWeeks.length - 1].name === options[options.length - 1].name){
            return true;
        }
    }else if (flightWeeks.length === options.length){
        return true;
    }
    return false;
}

export function isMultiOptionSelected(item: LookupItem, selectedList: LookupItem[]): LookupItem {
    return selectedList.find(listItem => isSingleOptionSelected(listItem, item));
}

export function isSingleOptionSelected(item: LookupItem | NumericLookupItem, selected: LookupItem | NumericLookupItem): boolean {
    return item && selected
        ? item.id === selected.id
        : item === selected;
}

export function isMultiOptionSelectedByName(item: LookupItem, selectedList: LookupItem[]): LookupItem {
    return selectedList.find(listItem => isSingleOptionSelectedByName(listItem, item));
}

export function isSingleOptionSelectedByName(item: LookupItem, selected: LookupItem): boolean {
    return item && selected
        ? item.name === selected.name
        : item === selected;
}

export function getInputTarget(item: ConstraintMix, inputs: ConstraintMix[]): number {
    const targetItem = inputs.find(input => input.name === item.name && input.id === item.id);
    return targetItem ? targetItem.target : undefined;
}

export function removeCommasFromValue(val: string | number): string {
    if (val) {
        return val.toString().replace(/,/g, '');
    }
    return String(val);
}

export function generateCachedGet<T>(
    clearCache: boolean,
    url: string,
    http: HttpClient,
    params: any,
    cache: any,
): Observable<T> {

    if (params) {
        url = url.concat('?', (new HttpParams().set('requestData', JSON.stringify(params)).toString()));
    }
    if (clearCache) {
        cache[url] = null;
    }

    if (!cache[url]) {
        return http?.get<T>(url).pipe(
            switchMap((data: T) => {
                cache[url] = data;
                return of(cloneDeep(cache[url]));
            }),
            publishReplay(1),
            refCount(),
        );
    }

    return of(cloneDeep(cache[url]));
}

export function generateUncachedGet<T>(
    url: string,
    http: HttpClient,
    params: any,
    getParams = {},
): Observable<T> {
    if (params) {
        url = url.concat('?', (new HttpParams().set('requestData', JSON.stringify(params)).toString()));
    }
    return http.get<T>(url, getParams);
}

export function sumConstraintMix(items: ConstraintMix[]): number {
    let sum = 0;
    items.forEach(item => item.target ? sum += item.target : sum += 0);
    return sum;
}

export enum ConstraintMixState {
    Defined,
    Undefined
}

export function isPartiallyDefinied(items: ConstraintMix[]): boolean {
    return items.reduce((result, item) => {
        result.add(item?.target == null ? ConstraintMixState.Undefined : ConstraintMixState.Defined);
        return result;
    }, new Set()).size > 1;
}

export function defaultZeroIfPartiallyDefined(items: ConstraintMix[]): ConstraintMix[] {
    const partiallyDefined = isPartiallyDefinied(items);
    return items.map((item: ConstraintMix) => {
        if (partiallyDefined){
            item.target = item?.target != null ? item.target : 0;
        }
        return item;
    });
}

export function parseAnalysisTables(params): Grid<any>[] {
    // TODO: Maybe move this to the api
    return Object.keys(params).map(key => {
        const grid = params[key];
        if (grid.columns.length !== 0) {
            grid.columns.map(column => column.field = column.name);
            grid.data = grid.data.map(dataArr => dataArr[0]);
            grid.data.forEach(data => {
                switch (data.metric) {
                    case 'spot_count':
                        data.type = 'Spot Count';
                        data.format = {
                            type: 'number',
                            format: '1.0-0'
                        };
                        break;
                    case 'paid_spot_count':
                        data.type = 'Paid Spot Count';
                        data.format = {
                            type: 'number',
                            format: '1.0-0'
                        };
                        break;
                    case 'bonus_spot_count':
                        data.type = 'Unpaid Spot Count';
                        data.format = {
                            type: 'number',
                            format: '1.0-0'
                        };
                        break;
                    case 'spot_percent':
                        data.type = 'Spot Percent';
                        data.format = {
                            type: 'percent',
                            format: '1.0-0'
                        };
                        break;
                    case 'gross_revenue':
                        data.type = 'Gross Revenue';
                        data.format = {
                            type: 'currency',
                            format: '1.2'
                        };
                        break;
                    case 'gross_revenue_percent':
                        data.type = 'Gross Revenue Percent';
                        data.format = {
                            type: 'percent',
                            format: '1.0-0'
                        };
                        break;
                }
            });
        }
        grid.name = key;
        return grid;
    });
}

export function parseScenarioProposalTable(params, hasPlanning: boolean): Grid<any>[] {
    // TODO: Remove this when line details is fixed
    delete params['Line Details'];
    // TODO: Maybe move this to the api
    return Object.keys(params).filter(key => key !== 'Has Snapshot History').map(key => {
        const grid: Grid<any> = params[key];

        grid.columns.map(column => {
            if (
                key !== 'Subtotals' && (
                    column.field === 'unitRate'
                    || column.field === 'channel'
                    || column.field === 'spotType'
                    || column.field === 'daypart'
                    || column.field === 'spotLength'
                    || column.field === 'comment'
                    || column.field === 'averageSpots'
                    || column.field === 'category'
                )
            ) {
                column.isEditable = true;
            }

            // line breaks in the header
            if (LINE_BREAK_HEADERS.includes(column.field) && column.field !== 'averageSpots') {
                const splits = column.name.split(' ');
                column.name = splits.length >= MAXIMUM_RETURNS ?
                    splits.slice(FIRST_ARRAY_INDEX, MAXIMUM_RETURNS).join(RETURN_STRING) +
                    splits.slice(MAXIMUM_RETURNS).join(SPACE_STRING) : splits.join(RETURN_STRING);
            }
            // average spots per week looked pretty bad with above rule
            if (column.field === 'averageSpots') {
                column.name = column.name.split(' / ').join('\n/');
            }
            // case for live remaining avails
            if (column.field === 'liveAvails') {
                column.name = 'Live Remaining\nAvails';
            }
        });
        grid.data.map(dataLine => dataLine.id = dataLine.proposalLineId);
        // Sanity check on proposal table. Duplicate IDs causes bizarre display issues
        if (
            [...new Set(grid.data.map(row => row.id))].length !== grid.data.map(row => row.id).length) {
            throw new Error('Invalid Proposal Table');
        }
        const totalRowIndex = grid.data.findIndex(data => data.id === TOTAL_ROW_ID);
        const totalRow = grid.data.splice(totalRowIndex, 1);
        const weeklyRevenueIndex = grid.data.findIndex(data => data.id === TOTAL_WEEKLY_ROW_ID);
        const weeklyRevenueRow = grid.data.splice(weeklyRevenueIndex, 1);
        grid.data.push(...totalRow, ...weeklyRevenueRow);

        if (!hasPlanning) {
            const planningViewIndex = grid.views.findIndex(view => view.name.toLocaleLowerCase().includes('planning'));
            if (planningViewIndex !== -1) {
                grid.views.splice(planningViewIndex, 1);
            }
        }

        grid.name = key;
        return grid;
    });
}

/*
        1. Take in all users selected dates
        2. Find the years they span and determine quarterly date range arrays for those years
        3. Return arrays with each week from allWeeks in appropriate box
     */
export function splitDateRangeWeeks(allWeeks: ColDef[],
                                    firstAndLastWeek: Moment[],
                                    dateFormat: string,
                                    weekWidth: number = 25,
                                    splitByQuarters: boolean = true): ColDef[][] {
    if (allWeeks.length <= MAX_WEEKS_ALLOWED_BEFORE_QUARTERLY_BREAKDOWN) {
        allWeeks.forEach(week => week.width = weekWidth);
        return [allWeeks];
    } else if (splitByQuarters) {
        const yearSpan = [];
        const dateRanges = [];
        // Determine the broadcast years. Need to add 6 days in the case that the 1st day of the year is Tues-Sun
        const broadcastYearStart = moment(firstAndLastWeek[0].add(NOT_QUITE_ONE_WEEK, 'days')).year();
        const broadcastYearEnd = moment(firstAndLastWeek[1]).add(NOT_QUITE_ONE_WEEK, 'days').year();
        yearSpan.push(broadcastYearStart);
        if (broadcastYearStart !== broadcastYearEnd) {
            for (let i = 1; i <= broadcastYearEnd - broadcastYearStart; i++) {
                yearSpan.push(broadcastYearStart + i);
            }
        }

        // Get skeleton array with all relevant date ranges
        const dateRangeStarts = getAllQuarters(yearSpan);
        dateRangeStarts.forEach((startDate) => {
            dateRanges.push([]);
        });
        // Assigning weeks to the appropriate date range box
        allWeeks.forEach(week => {
            for (let i = 0; i < dateRangeStarts.length; i++) {
                if ((moment(week.field, dateFormat) >= dateRangeStarts[i])
                    && (!dateRangeStarts[i + 1] ||
                        moment(week.field, dateFormat) < dateRangeStarts[i + 1])) {
                    week.width = weekWidth;
                    dateRanges[i].push(week);
                }
            }
        });

        // Remove empty quarters before returning
        return dateRanges.filter(ranges => ranges.length > 0);
    } else {
        const dateRanges = [];
        for (let i = 0; i < (allWeeks.length / MAX_WEEKS_ALLOWED_BEFORE_QUARTERLY_BREAKDOWN); i++) {
            dateRanges.push([]);
        }
        allWeeks.forEach((week, index) => {
            week.width = weekWidth;
            dateRanges[Math.floor(index / MAX_WEEKS_ALLOWED_BEFORE_QUARTERLY_BREAKDOWN)].push(week);
        });
        return dateRanges;
    }
}

/*
    1. Takes in all years over a scenario
    2. Per each year determines date range boxes
    3. Each range starts at the 1st of every three months minus days to get to the monday of that week
    4. Return the array with start date for each range
 */
export function getAllQuarters(years: string[]): Moment[] {
    const quarters = [];
    years.forEach((year) => {
        // making default first day of every third month
        const firstOfEachQuarter = [
            moment(FIRST_QUARTER_BASE_MM_DD + year, 'MMDDYYYY'),
            moment(SECOND_QUARTER_BASE_MM_DD + year, 'MMDDYYYY'),
            moment(THIRD_QUARTER_BASE_MM_DD + year, 'MMDDYYYY'),
            moment(FOURTH_QUARTER_BASE_MM_DD + year, 'MMDDYYYY'),
        ];
        firstOfEachQuarter.forEach((firstDate) => {
            // Get real quarter start date.. the Monday of the week of the first day of the month
            // isoWeekDay return 1-7 for DOW, Monday is 1. We don't want to subtract anything from the date if we are already on Monday
            const realQuarterStartDate = firstDate.subtract(firstDate.isoWeekday() - DEFAULT_MOMENT_WEEKDAY_START_COUNT, 'days');
            quarters.push(realQuarterStartDate);
        });
    });

    return quarters;

}

export function isStandardDaypart(daypartId: number) {
    return daypartId.toString().startsWith(WIDEORBIT_SOURCE_SYSTEM_ID);
}

export function downloadRemoteFile(url: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.target = '_blank';
    a.click();
}

export function multiply(a, b) {
    a = isNaN(Number(a)) ? 0 : Number(a);
    b = isNaN(Number(b)) ? 0 : Number(b);

    return a * b;
}

export function downloadZipFile(data: string, fileName: string) {
    const byteChars = atob(data);
    const byteNumbers = [];
    for (let i = 0; i < byteChars.length; i++) {
        byteNumbers.push(byteChars.charCodeAt(i));
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: 'application/zip'});

    const a: any = document.createElement('a');
    document.body.appendChild(a);

    a.style = 'display: none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);

}

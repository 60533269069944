import {environment} from 'src/environments/environment';
import {Injectable} from '@angular/core';

const RADIO = 'radio';
const PROPOSALS = 'proposals';
const SCENARIOS = 'scenarios';
const VERSION = 'v1';
const LOOKUPS = 'lookups';
const SALESFORCE = 'salesforce';

@Injectable({
    providedIn: 'root'
})
export class UrlStore {

    public static readonly ui = {
        landing: '/',
        loginError: 'login/error',
        login: 'login',
        accessDenied: 'accessDenied',
        siteDown: 'site-down',
        tv: {
            admin: 'tv/admin',
            proposalProAdmin: 'tv/admin/ProposalPRO',
            proposalPro: 'tv/ProposalPRO',
            proposalProSummary: 'tv/ProposalPRO/ProposalSummary',
            proposalProOptimize: 'tv/ProposalPRO/ProposalBuilder',
            proposalProRateGuide: 'tv/ProposalPRO/RateGuide',
            inventoryReport: 'radio/ProposalPRO/InventoryReport'
        },
        radio: {
            admin: 'radio/admin',
            proposalProAdmin: 'radio/admin/ProposalPRO',
            proposalPro: 'radio/ProposalPRO',
            proposalProSummary: 'radio/ProposalPRO/ProposalSummary',
            proposalProOptimize: 'radio/ProposalPRO/ProposalBuilder',
            proposalProLaunchPick: 'radio/ProposalPRO/LaunchPadPick',
            proposalProLaunchBuild: 'radio/ProposalPRO/LaunchPadBuild',
            proposalProLaunchSearch: 'radio/ProposalPRO/LaunchPadSearch',
            proposalProRateGuide: 'radio/ProposalPRO/RateGuide',
            inventoryManagement: 'radio/ProposalPRO/InventoryManagement',
            inventoryReport: 'radio/ProposalPRO/InventoryReport',
            postBuyAnalysis: 'radio/ProposalPRO/PostBuyAnalysis'
        },
    };

    public static readonly api = {
        config: `${environment.api}/${VERSION}/config`,
        zendeskToken: `${environment.api}/${VERSION}/integration/zendesk-token`,
        lookups: {
            periods: `${environment.api}/${VERSION}/${LOOKUPS}/period`,
            markets: `${environment.api}/${VERSION}/${LOOKUPS}/markets`,
            stations: `${environment.api}/${VERSION}/${LOOKUPS}/stations`,
            channels: `${environment.api}/${VERSION}/${LOOKUPS}/channels`,
            priorityCodes: `${environment.api}/${VERSION}/${LOOKUPS}/priority_codes`,
            categories: `${environment.api}/${VERSION}/${LOOKUPS}/categories`,
            dayparts: `${environment.api}/${VERSION}/${LOOKUPS}/daypart`,
            closeReasons: `${environment.api}/${VERSION}/${LOOKUPS}/close_reason`,
            userRegions: `${environment.api}/${VERSION}/${LOOKUPS}/user_region`,
            userRoles: `${environment.api}/${VERSION}/${LOOKUPS}/user_role`,
            spotTypes: `${environment.api}/${VERSION}/${LOOKUPS}/spot_types`,
            spotLengths: `${environment.api}/${VERSION}/${LOOKUPS}/spot_length`,
            pricingModels: `${environment.api}/${VERSION}/${LOOKUPS}/pricing_model`,
            buyTypes: `${environment.api}/${VERSION}/${LOOKUPS}/buy_type`,
            optPriorities: `${environment.api}/${VERSION}/${LOOKUPS}/optimization_priority`,
            channelTree: `${environment.api}/${VERSION}/${LOOKUPS}/channel_tree`,
            agencies: `${environment.api}/${VERSION}/${LOOKUPS}/agencies`,
            advertisers: `${environment.api}/${VERSION}/${LOOKUPS}/advertisers`,
            proposalProNames: `${environment.api}/${VERSION}/${LOOKUPS}/proposal_pro_names`,
            closeProbabilities: `${environment.api}/${VERSION}/${LOOKUPS}/closeprobability`,
            exclusions: `${environment.api}/${VERSION}/${LOOKUPS}/exclusion_options`,
            optSegmentations: `${environment.api}/${VERSION}/${LOOKUPS}/optimization_segmentation`,
            demographic: `${environment.api}/${VERSION}/${LOOKUPS}/demographic`,
            demographicEdison: `${environment.api}/${VERSION}/${LOOKUPS}/demographic_edison`,
            scenarioStatus: `${environment.api}/${VERSION}/${LOOKUPS}/scenario_status`,
            survey: `${environment.api}/${VERSION}/${LOOKUPS}/survey`,
            accountExecutives: `${environment.api}/${VERSION}/${LOOKUPS}/account_executives`,
            appUsers: `${environment.api}/${VERSION}/${LOOKUPS}/app_users`,
            planningMembers: `${environment.api}/${VERSION}/${LOOKUPS}/planning_members`,
            nielsenOrEdisonNames: `${environment.api}/${VERSION}/${LOOKUPS}/ratings_channels`,
            ratingsDayparts: `${environment.api}/${VERSION}/${LOOKUPS}/ratings_daypart`,
            globalCustomDaypartHours: `${environment.api}/${VERSION}/${LOOKUPS}/global_custom_daypart_hours`,
            salesCoordinators: `${environment.api}/${VERSION}/${LOOKUPS}/sales_coordinators`,
            daypartHours: `${environment.api}/${VERSION}/${LOOKUPS}/daypart_hours`,
            planDesignations: `${environment.api}/${VERSION}/${LOOKUPS}/plan_designations`,
            checkSalesForceid: `${environment.api}/${VERSION}/${LOOKUPS}/check_salesforce_id`,
        },
        proposalPro: {
            proposal: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}`,
            proposalMetrics: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/metricsettings`,
            proposalDetails: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/details`,
            proposalList: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/list`,
            existingProposalList: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/launchpad_list`,
            copyProposalInputs: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/clone_inputs`,
            submitProposal: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/submit_for_approval`,
            submitProposalForMerge: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/submit_for_approval_merge`,
            unsubmitProposal: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/unsubmit_for_approval`,
            changedSincePending: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/changed_since_pending`,
            autoApproval: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/auto_approve_status`,
            publishProposal: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/send_to_wideorbit`,
            approveProposal: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/approve_scenario`,
            rejectProposal: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/reject_scenario`,
            copyProposal: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/clone_history`,
            customDaypartUpdate: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/custom_daypart_update`,
            createCustomDaypart: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/create_custom_daypart`,
            createBulkCustomDaypart: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/create_bulk_custom_daypart`,
            canUserViewProposal: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/can_user_view`,
            scenarioInputs: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/inputs`,
            scenario: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}`,
            optimize: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/optimize`,
            optimizeHistory: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/optimize_history`,
            makePrimary: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/make_primary`,
            proposalLines: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/lines`,
            analysis: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/analysis`,
            scenarioProposal: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/proposals`,
            scenarioSummary: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/summary`,
            scenarioComparison: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/scenario_comparison`,
            nielsenOrEdisonRatings: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/refresh_net_reach_and_frequency`,
            edisonRatingsStatus: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/refresh_net_reach_and_frequency_status`,
            refreshToggleValue: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/refresh_net_reach_and_frequency_toggle`,
            edisonRatingsStatusPoll: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/refresh_net_reach_and_frequency_poll`,
            lineComments: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/proposal_line_comments`,
            updateLine: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/proposal_line_rate`,
            copyScenario: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/copy_scenario`,
            proposalComments: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/comment`,
            proposalDifferences: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/snapshot_diff`,
            bulkAddLines: `${environment.api}/${VERSION}/${RADIO}/${PROPOSALS}/bulk_add_lines`,
            updateAgencies: `${environment.api}/${VERSION}/${RADIO}/${SALESFORCE}/update_agency`,
            updateAdvertisers: `${environment.api}/${VERSION}/${RADIO}/${SALESFORCE}/update_advertiser`,
            mergeProposals: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/merge_proposals`,
            matchingProposals: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/advertiser_agency_proposals`,
            mergeAnalysis: `${environment.api}/${VERSION}/${RADIO}/${SCENARIOS}/merge_analysis`,

        },
        inventoryAvailability: {
            availability: `${environment.api}/${VERSION}/${RADIO}/inventory/availability`
        },
        rateGuide: {
            dayparts: `${environment.api}/${VERSION}/${RADIO}/rate_guide/dayparts`,
            spotTypes: `${environment.api}/${VERSION}/${RADIO}/rate_guide/spot_types`,
            rateGuide: `${environment.api}/${VERSION}/${RADIO}/rate_guide`,
            save: `${environment.api}/${VERSION}/${RADIO}/rate_guide/save`
        },
        woms: {
            xml: `${environment.api}/${VERSION}/${RADIO}/scenarios/wideorbit_xml`
        },
        ams: {
            xml: `${environment.api}/${VERSION}/${RADIO}/scenarios/agency_xml`
        },
        admin: {
            approverSettings: `${environment.api}/${VERSION}/${RADIO}/admin/approver_settings`,
            channelAttributes: `${environment.api}/${VERSION}/${RADIO}/admin/channel_attributes`,
            woAdvertisersAgencies: `${environment.api}/${VERSION}/${RADIO}/admin/wo_advertiser_agency`,
            woAdvertisersAgenciesOriginal: `${environment.api}/${VERSION}/${RADIO}/admin/wo_advertiser_agency_original`,
            unmappedAdvertisersAgencies: `${environment.api}/${VERSION}/${RADIO}/admin/unmapped_advertiser_agency`,
            saveChannelAttributes: `${environment.api}/${VERSION}/${RADIO}/admin/save_channel_attributes`,
            inventoryManagement: `${environment.api}/${VERSION}/${RADIO}/admin/inventory_management`,
            ratingsDaypartMapping: `${environment.api}/${VERSION}/${RADIO}/admin/daypart_mapping`,
            globalCustomDayparts: `${environment.api}/${VERSION}/${RADIO}/admin/global_custom_dayparts`,
            proposalProUsers: `${environment.api}/${VERSION}/${RADIO}/admin/users`
        },
        status: {
            systemDataStatus: `${environment.api}/${VERSION}/status/system_data`,
            siteDownStatus: `${environment.api}/${VERSION}/status/site-down`,
            siteDownWarning: `${environment.api}/${VERSION}/status/site-down-warning`,
            siteHeartbeat: `${environment.api}/${VERSION}/status`
        },
        postBuyAnalysis: {
            postanalysis: `${environment.api}/${VERSION}/${RADIO}/post_buy_analysis`,
            advertisers: `${environment.api}/${VERSION}/${RADIO}/post_buy_analysis/advertisers`,
            agency: `${environment.api}/${VERSION}/${RADIO}/post_buy_analysis/agency`,
            order: `${environment.api}/${VERSION}/${RADIO}/post_buy_analysis/orders`,
            ratings: `${environment.api}/${VERSION}/${RADIO}/post_buy_analysis/ratings`,
        }
    };

    constructor() {
    }
}
